@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
/* You can add global styles to this file, and also import other style files */
/*
@File: Admin Dashboard Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Sidemenu CSS
** - Top Navbar CSS
** - Main Content CSS
** - App Email CSS
** - App Email Read CSS
** - App Email Compose CSS
** - App Todo CSS
** - App Calendar CSS
** - App Chat CSS
** - My Profile CSS
** - Footer CSS
*/

/*================================================
Default CSS
=================================================*/

$font-family2: 'Open Sans', sans-serif;
$font-family: 'Cabin', sans-serif;

$kgreen-color: #0B9F57;
$kred-color: #E5151C;
$kblue-color:#1A7CAD;

$main-color: $kblue-color;
$main-color2: $kgreen-color;

$black-color: #000000;
$white-color: #ffffff;
$optional-color: #4a6f8a;
$font-size: 15px;
$transition: .5s;

body {
    color: $black-color;
    background-color: #fafafa;
    padding: 0;
    margin: 0;
    font: {
        size: $font-size;
        family: $font-family
    };
}
img {
    max-width: 100%;
    height: auto;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $black-color;
    font: {
        family: $font-family
    };
}
a {
    transition: $transition;
    outline: 0 !important;
}
p {
    line-height: 1.8;
}
.mb-30 {
    margin-bottom: 30px;
}
.mt-20 {
    margin-top: 20px;
}
button, input {
    outline: 0 !important;
}
.btn-outline-light {
    color: #212529;
}
:foucs {
    outline: 0 !important;
}
/*dropdown-css*/
.dropdown-menu {
    box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, .2);
    background-color: $white-color;
    border-color: #eeeeee;

    .form-control {
        border-radius: 5px;
        height: 45px;
    }
}
/*progress-css*/
.progress + .progress {
    margin-top: 1rem;
}
/*modal-css*/
.modal {
    p {
        line-height: 1.8;
        color: #727E8C;
    }
}
/*forms*/
.form-check-input {
    margin-top: .20rem;
}
.form-control {
    &:focus {
        box-shadow: unset;
        border-color: $main-color;
    }
}

/*================================================
Sidemenu CSS
=================================================*/
.sidemenu-area {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 250px;
    z-index: 9999;
    transition: $transition;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2); /* Add a box shadow */

    .sidemenu-header {
        background: $white-color;
        padding: 0 20px;
        border-bottom: 1px solid #f2f4f9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        width: 100%;
        transition: $transition;

        .navbar-brand {
            padding: 20px;

            span {
                position: relative;
                color: $black-color !important;
                margin-left: 10px;
                transition: $transition;
                font: {
                    size: 25px;
                    weight: 700;
                };
            }
        }
        .burger-menu {
            cursor: pointer;
            transition: $transition;

            span {
                height: 1px;
                width: 25px;
                background: $black-color;
                display: block;
                margin: 6px 0;
                transition: all .50s ease-in-out;
            }
            &:hover{
                span {
                    &.top-bar {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                    }
                    &.middle-bar {
                        opacity: 0;
                    }
                    &.bottom-bar {
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                        margin-top: 5px;
                    }
                }
            }
            &.active {
                span {
                    &.top-bar {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                    }
                    &.middle-bar {
                        opacity: 0;
                    }
                    &.bottom-bar {
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                        margin-top: 5px;
                    }
                }
            }
        }
        .responsive-burger-menu {
            cursor: pointer;
            transition: $transition;
            @media (max-width: 767px) { // Adjust the breakpoint as needed
                padding-left: 10px;
            }
            span {
                height: 1px;
                width: 25px;
                background: $black-color;
                display: block;
                margin: 6px 0;
                transition: all .50s ease-in-out;
            }
            &.hover{
                span {
                    &.top-bar {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                    }
                    &.middle-bar {
                        opacity: 0;
                    }
                    &.bottom-bar {
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                        margin-top: 5px;
                    }
                }
            }
            &.active {
                span {
                    &.top-bar {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                    }
                    &.middle-bar {
                        opacity: 0;
                    }
                    &.bottom-bar {
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .sidemenu-body {
        max-height: calc(100% - 80px);
        position: relative;
        height: 100%;
        box-shadow: 0 8px 10px 0 rgba(183, 192, 206, .10);
        background: $white-color;
        overflow: hidden;

        .sidemenu-nav {
            padding: 20px 0;
            list-style-type: none;
            margin-bottom: 0;

            .nav-item-title {
                color: #686868;
                display: block;
                text-transform: uppercase;
                margin-bottom: 7px;
                font: {
                    size: 13px;
                    weight: 600;
                };
                padding: {
                    left: 20px;
                    right: 20px;
                };
                &:not(:first-child) {
                    margin-top: 20px;
                }
            }
            .nav-item {
                .nav-link {
                    color: #7e7e7e;
                    padding: 10px 20px;
                    position: relative;
                    z-index: 1;
                    font: {
                        size: $font-size;
                        weight: 500;
                    };
                    .icon {
                        color: $black-color;
                        transition: $transition;
                        display: inline-block;
                        margin-right: 8px;
                        font-size: 20px;
                        position: relative;
                        top: 2px;

                        .bx-user-pin {
                            position: relative;
                            top: 2px;
                        }
                        .bx-log-out {
                            position: relative;
                            top: 1px;
                        }
                    }
                    .badge {
                        background-color: #43d39e;
                        color: $white-color;
                        transition: $transition;
                        margin-left: 7px;
                        position: relative;
                        top: -1px;
                        padding: 4px 7px 4px 6px;
                    }
                    &.disabled {
                        color: #a3a3a3;

                        i {
                            color: #a3a3a3;
                        }
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 0;
                        transition: $transition;
                        background: $main-color;
                    }
                    &.collapsed-nav-link {
                        position: relative;

                        &::after {
                            content: "\e9f9";
                            position: absolute;
                            right: 15px;
                            top: 9px;
                            color: #7e7e7e;
                            transition: $transition;
                            font: {
                                family: "boxicons" !important;
                                weight: normal;
                                style: normal;
                                variant: normal;
                                size: 18px;
                            };
                        }
                    }
                    &:hover {
                        background-color: #f0f8fb;
                        color: $main-color;
    
                        &::before {
                            width: 3px;
                        }
                        .icon {
                            transform: rotateY(-180deg);
                            color: $main-color;
                        }
                    }
                    &.active {
                        background-color: #f0f8fb;
                        color: $main-color;
    
                        &::before {
                            width: 3px;
                        }
                        .icon {
                            color: $main-color;
                        }
                    }
                }
                &.mm-active, &.active {
                    .nav-link {
                        background-color: #f0f8fb;
                        color: $main-color;
    
                        &::before {
                            width: 3px;
                        }
                        .icon {
                            color: $main-color;
                        }
                        &.collapsed-nav-link {
                            &::after {
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
                .sidemenu-nav-second-level {
                    list-style-type: none;
                    padding-left: 30px;
                    margin-bottom: 0;

                    .nav-item {
                        .nav-link {
                            color: #7e7e7e;
                            background-color: transparent !important;
                            padding: {
                                left: 0;
                                right: 0;
                                bottom: 5px;
                            };
                            .icon {
                                color: $black-color;
                            }
                            &::before {
                                display: none;
                            }
                            &.collapsed-nav-link {
                                &::after {
                                    transform: rotate(0deg);
                                }
                            }
                            &:hover {
                                color: $main-color;

                                .icon {
                                    color: $main-color;
                                }
                            }
                        }
                        &.mm-active, &.active {
                            .nav-link {
                                color: $main-color;

                                .icon {
                                    color: $main-color;
                                }
                                &.collapsed-nav-link {
                                    &::after {
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                        }
                        .sidemenu-nav-third-level {
                            list-style-type: none;
                            padding-left: 15px;
                            margin-bottom: 0;
        
                            .nav-item {
                                .nav-link {
                                    color: #7e7e7e;
                                    background-color: transparent !important;
                                    padding: {
                                        left: 0;
                                        right: 0;
                                        bottom: 5px;
                                    };
                                    .icon {
                                        color: $black-color;
                                    }
                                    &::before {
                                        display: none;
                                    }
                                    &.collapsed-nav-link {
                                        &::after {
                                            transform: rotate(0deg);
                                        }
                                    }
                                    &:hover {
                                        color: $main-color;
        
                                        .icon {
                                            color: $main-color;
                                        }
                                    }
                                }
                                &.mm-active, &.active {
                                    .nav-link {
                                        color: $main-color;
        
                                        .icon {
                                            color: $main-color;
                                        }
                                        &.collapsed-nav-link {
                                            &::after {
                                                transform: rotate(-90deg);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.toggle-sidemenu-area {
        width: 55px;

        .sidemenu-header {
            padding: 0 10px;

            .navbar-brand {
                display: block !important;
                @media (max-width: 767px) { // Adjust the breakpoint as needed
                    display: none !important; // Hide on smaller screens (e.g., mobile)
                    
                }
                span {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            .burger-menu {
                opacity: 0;
                visibility: hidden;
            }
        }
        .sidemenu-body {
            .sidemenu-nav {
                .nav-item-title {
                    display: none;
                }
                .nav-item {
                    .nav-link {
                        .menu-title {
                            display: none;
                        }
                        &::after {
                            display: none;
                        }
                        .badge {
                            display: none;
                        }
                    }
                    .sidemenu-nav-second-level {
                        padding-left: 20px;

                        .nav-item {
                            .sidemenu-nav-third-level {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    &:hover {
        &.toggle-sidemenu-area {
            width: 250px;
    
            .sidemenu-header {
                padding: 0 20px;
    
                .navbar-brand {
                    display: flex !important;
                    
                    span {
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .burger-menu {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .sidemenu-nav {
                .nav-item-title {
                    display: block;
                }
                .nav-item {
                    .nav-link {
                        .menu-title {
                            display: inline-block;
                        }
                        &::after {
                            display: block;
                        }
                        .badge {
                            display: inline-block;
                        }
                    }
                    .sidemenu-nav-second-level {
                        padding-left: 30px;

                        .nav-item {
                            .sidemenu-nav-third-level {
                                padding-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Top Navbar CSS
=================================================*/
.top-navbar {
    &.navbar {
        padding: {
            left: 0;
            right: 0;
            top: 20px;
        };
        .responsive-burger-menu {
            cursor: pointer;
            transition: $transition;
            @media (max-width: 767px) { // Adjust the breakpoint as needed            
                padding-right: 100px;
            }
            span {
                height: 1px;
                width: 25px;
                background: $black-color;
                display: block;
                margin: 6px 0;
                transition: all .50s ease-in-out;
            }
            &.active {                
                span {
                    &.top-bar {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                    }
                    &.middle-bar {
                        opacity: 0;
                    }
                    &.bottom-bar {
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                        margin-top: 5px;
                    }
                }
            }
        }
        .nav-search-form {
            position: relative;
            width: 300px;
    
            label {
                display: block;
                margin-bottom: 0;
                position: absolute;
                left: 15px;
                top: 11.3px;
                font-size: 18px;
                color: #a8a8a8;
            }
            .form-control {
                height: 45px;
                border-radius: 30px;
                background-color: #eef5f9;
                border: none;
                color: $black-color;
                padding: 0 15px 0 40px;
                transition: $transition;
                box-shadow: unset;
                font: {
                    size: 14px;
                    weight: 400;
                };
                &:focus {
                    box-shadow: unset;
                    background-color: #eef5f9;

                    &::placeholder {
                        color: transparent !important;
                    }
                }
                &::placeholder {
                    color: #a8a8a8;
                    transition: $transition;
                }
            }
        }
        .nav-item {
            margin-left: 15px;
            
            .nav-link {
                padding: 0;
                color: #525f80;
                font-weight: 500;
    
                &.dropdown-toggle {
                    &::after {
                        display: none;
                    }
                }
                .bx-chevron-down {
                    font-size: 18px;
                    position: relative;
                    display: inline-block;
                    top: 3px;
                    margin-left: -4px;
                    color: #525f80;
                }
                img {
                    width: 30px;
                    margin-right: 3px;
                }
                .badge {
                    position: absolute;
                    top: -2px;
                    right: -7px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    font-size: 9px;
                    line-height: 12px;
    
                    &.badge-secondary {
                        background: $main-color;
                        animation: pulse-secondary 2s infinite;
                        box-shadow: 0 0 0 rgba(8, 141, 211, 0.9);
                    }
                    &.badge-primary {
                        background: #2962ff;
                        animation: pulse-primary 2s infinite;
                        box-shadow: 0 0 0 rgba(41, 98, 255, 0.9);
                    }
                }
                &.bx-fullscreen-btn {
                    font-size: 22px;
                    position: relative;
                    cursor: pointer;
                    top: 3px;
                    margin-right: 5px;
    
                    &:hover {
                        color: $main-color;
                    }
                    &.active {
                        i {
                            &::before {
                                content: "\ea5d";
                            }
                        }
                    }
                }
            }
            &.dropdown {
                .dropdown-menu {
                    max-width: 280px;
                    position: absolute;
                    right: 0;
                    left: auto;
                    border-radius: 2px;
                    margin-top: 20px;
                    border: none;
                    display: block;
                    transition: .3s;
                    opacity: 0;
                    visibility: hidden;
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
                    background-color: $white-color;
    
                    &.show {
                        margin-top: 10px;
                        opacity: 1;
                        visibility: visible;
                    }
                    .dropdown-item {
                        color: $black-color;
                        padding: 5px 15px;
                        position: relative;
                        font: {
                            size: $font-size;
                        };
                        &.active, &:active {
                            color: $black-color;
                            background-color: transparent;
                            background-color: #f8f9fa;
                        }
                    }
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &.language-switch-nav-item {
                .nav-link {
                    img {
                        width: 25px;
                        margin-right: 7px;
                        position: relative;
                        top: -2px;
                        border-radius: 2px;
                    }
                }
                &.dropdown {
                    .dropdown-menu {
                        left: 50%;
                        right: auto;
                        transform: translateX(-50%);

                        .dropdown-item {
                            padding: 5px 20px;
                            font-size: 14.5px;
    
                            img {
                                width: 27px;
                                border-radius: 2px;
                            }
                            span {
                                font-weight: 600;
                            }
                        }
                        &.active {
                            opacity: 1;
                            margin-top: 10px;
                            visibility: visible;
                        }
                    }
                }
            }
            &.notification-box {
                .dropdown-menu {
                    width: 350px;
                    max-width: 350px;
                    border-radius: 5px 5px 0 0;
                    padding: 0;
    
                    .dropdown-header {
                        border-radius: 5px 5px 0 0;
                        background-color: $main-color;
                        color: $white-color;
                        padding: {
                            left: 15px;
                            right: 15px;
                            top: 9px;
                            bottom: 7px;
                        };
                        span {
                            font: {
                                size: 13px;
                                weight: 600;
                            };
                            &.mark-all-btn {
                                cursor: pointer;
                            }
                        }
                    }
                    .dropdown-body {
                        padding: {
                            top: 10px;
                            bottom: 10px;
                        };
                        .dropdown-item {
                            padding: 7px 15px;
    
                            .icon {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                position: relative;
                                background: #f7f7f7;
                                text-align: center;
                                color: $main-color;
                                transition: $transition;
    
                                i {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                            .content {
                                padding-left: 15px;
    
                                span {
                                    color: $black-color;
                                    margin-bottom: 1px;
                                    font: {
                                        size: 14.5px;
                                        weight: 600;
                                    };
                                }
                                p {
                                    font-size: 12px;
                                    color: #686868;
                                }
                            }
                            &:hover {
                                .icon {
                                    background-color: $main-color;
                                    color: $white-color;
                                }
                            }
                            &:nth-child(2) {
                                .icon {
                                    background: rgba(114, 124, 245, .1);
                                    color: #727cf5;
                                }
                                &:hover {
                                    .icon {
                                        background-color: #727cf5;
                                        color: $white-color;
                                    }
                                }
                            }
                            &:nth-child(3) {
                                .icon {
                                    background: rgba(16, 183, 89, .1);
                                    color: #10b759;
                                }
                                &:hover {
                                    .icon {
                                        background-color: #10b759;
                                        color: $white-color;
                                    }
                                }
                            }
                            &:nth-child(4) {
                                .icon {
                                    background: rgba(255, 51, 102, .1);
                                    color: #f36;
                                }
                                &:hover {
                                    .icon {
                                        background-color: #f36;
                                        color: $white-color;
                                    }
                                }
                            }
                            &:nth-child(5) {
                                .icon {
                                    background: rgba(251, 188, 6, .1);
                                    color: #fbbc06;
                                }
                                &:hover {
                                    .icon {
                                        background-color: #fbbc06;
                                        color: $white-color;
                                    }
                                }
                            }
                        }
                    }
                    .dropdown-footer {
                        text-align: center;
                        padding: 10px 15px;
                        border-top: 1px solid #eeeeee;
                        
                        .dropdown-item {
                            color: $main-color !important;
                            padding: 0;
                            position: relative;
                            display: inline-block;
                            width: auto;
                            background-color: transparent !important;
                            font: {
                                size: 14px;
                                weight: 600;
                            };
                        }
                    }
                    &.active {
                        opacity: 1;
                        margin-top: 10px;
                        visibility: visible;
                    }
                }
            }
            .notification-btn {
                font-size: 20px;
                position: relative;
                margin-right: 7px;
    
                i {
                    position: relative;
                    top: 3px;
                    animation: ring 4s .7s ease-in-out infinite;
                }
            }
            &.message-box {
                .dropdown-menu {
                    width: 350px;
                    max-width: 350px;
                    border-radius: 5px 5px 0 0;
                    padding: 0;
    
                    .dropdown-header {
                        border-radius: 5px 5px 0 0;
                        background-color: #2962ff;
                        color: $white-color;
                        padding: {
                            left: 15px;
                            right: 15px;
                            top: 9px;
                            bottom: 7px;
                        };
                        span {
                            font: {
                                size: 13px;
                                weight: 600;
                            };
                            &.clear-all-btn {
                                cursor: pointer;
                            }
                        }
                    }
                    .dropdown-body {
                        padding: {
                            top: 10px;
                            bottom: 10px;
                        };
                        .dropdown-item {
                            padding: 7px 15px;
    
                            .figure {
                                img {
                                    width: 35px;
                                    height: 35px;
                                }
                            }
                            .content {
                                width: 285px;
                                padding-left: 12px;
    
                                span {
                                    color: $black-color;
                                    margin-bottom: 2px;
                                    font: {
                                        size: 14.5px;
                                        weight: 600;
                                    };
                                }
                                .time-text {
                                    font-size: 13px;
                                    color: #686868;
                                }
                                .sub-text {
                                    font-size: 12.5px;
                                    color: #686868;
                                }
                            }
                            &:hover {
                                .icon {
                                    background-color: $main-color;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                    .dropdown-footer {
                        text-align: center;
                        padding: 10px 15px;
                        border-top: 1px solid #eeeeee;
                        
                        .dropdown-item {
                            color: #2962ff !important;
                            padding: 0;
                            position: relative;
                            display: inline-block;
                            width: auto;
                            background-color: transparent !important;
                            font: {
                                size: 14px;
                                weight: 600;
                            };
                        }
                    }
                    &.active {
                        opacity: 1;
                        margin-top: 10px;
                        visibility: visible;
                    }
                }
            }
            .message-btn {
                font-size: 20px;
                position: relative;
                margin-right: 7px;
    
                i {
                    position: relative;
                    top: 3px;
                }
            }
            &.apps-box {
                .dropdown-menu {
                    width: 350px;
                    max-width: 350px;
                    border-radius: 5px 5px 0 0;
                    padding: 0;
                    left: 0;
                    right: auto;
    
                    .dropdown-header {
                        border-radius: 5px 5px 0 0;
                        background-color: #2962ff;
                        color: $white-color;
                        padding: {
                            left: 15px;
                            right: 15px;
                            top: 9px;
                            bottom: 7px;
                        };
                        span {
                            font: {
                                size: 13px;
                                weight: 600;
                            };
                            &.edit-btn {
                                cursor: pointer;
                            }
                        }
                    }
                    .dropdown-body {
                        padding: 10px 5px;
    
                        .dropdown-item {
                            -ms-flex: 0 0 25%;
                            flex: 0 0 25%;
                            max-width: 25%;
                            text-align: center;
                            padding: 8px 10px;
    
                            span {
                                margin-top: 8px;
                                font-weight: 600;
                            }
                        }
                    }
                    .dropdown-footer {
                        text-align: center;
                        padding: 10px 15px;
                        border-top: 1px solid #eeeeee;
                        
                        .dropdown-item {
                            color: #2962ff !important;
                            padding: 0;
                            position: relative;
                            display: inline-block;
                            width: auto;
                            background-color: transparent !important;
                            font: {
                                size: 14px;
                                weight: 600;
                            };
                        }
                    }
                    &.active {
                        opacity: 1;
                        margin-top: 10px;
                        visibility: visible;
                    }
                }
            }
            button.nav-link {
                background-color: transparent;
                border: none;
                padding: 0;
            }
        }
        .left-nav {
            position: relative;
            top: 4px;

            .nav-item {
                .nav-link {
                    font-size: 22px;
    
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
        .right-nav {
            margin-left: 20px;
        }
        .profile-nav-item {
            .menu-profile {
                .name {
                    color: $black-color;
                    display: inline-block;
                    position: relative;
                    top: 2px;
                    font: {
                        weight: 600;
                    };
                }
                img {
                    width: 35px;
                    height: 35px;
                    display: inline-block;
                    margin: {
                        right: 0;
                        left: 9px;
                    };
                }
            }
            &.dropdown {
                .dropdown-menu {
                    padding: 15px 15px 10px;
                    min-width: 230px;
    
                    .dropdown-header {
                        padding: 0;
                        border-bottom: 1px solid #f2f4f9;
    
                        .figure {
                            position: relative;
    
                            img {
                                width: 80px;
                                height: 80px;
                            }
                        }
                        .name {
                            display: block;
                            color: $black-color;
                            margin-bottom: 2px;
                            font: {
                                size: 17px;
                                weight: 700;
                            };
                        }
                        .email {
                            color: #686868;
                        }
                    }
                    .dropdown-body {
                        ul {
                            padding-left: 0;
                            list-style-type: none;
                            margin-bottom: 0;
                        }
                        .nav-item {
                            margin-left: 0;
    
                            .nav-link {
                                color: $black-color;
                                padding: 5px 15px 5px 38px;
                                position: relative;
                                font: {
                                    size: 14px;
                                    weight: 600;
                                };
                                i {
                                    color: #686868;
                                    transition: $transition;
                                    position: absolute;
                                    left: 15px;
                                    top: 7px;
                                    font: {
                                        size: 16px;
                                    };
                                }
                                &.active, &:active {
                                    color: $black-color;
                                    background-color: transparent;
                                    background-color: #f8f9fa;
                                }
                                &:hover {
                                    i {
                                        transform: rotateY(-180deg);
                                    }
                                }
                            }
                        }
                    }
                    .dropdown-footer {
                        margin: 10px -15px 0;
                        padding: 10px 15px 0;
                        border-top: 1px solid #eeeeee;
    
                        ul {
                            padding-left: 0;
                            list-style-type: none;
                            margin-bottom: 0;
                        }
                        .nav-item {
                            .nav-link {
                                color: red;
                                padding: 5px 15px 5px 38px;
                                position: relative;
                                font: {
                                    size: 14px;
                                    weight: 600;
                                };
                                i {
                                    color: red;
                                    transition: $transition;
                                    position: absolute;
                                    left: 15px;
                                    top: 7px;
                                    font: {
                                        size: 16px;
                                    };
                                }
                                &.active, &:active {
                                    color: $black-color;
                                    background-color: transparent;
                                    background-color: #f8f9fa;
                                }
                                &:hover {
                                    i {
                                        transform: rotateY(-180deg);
                                    }
                                }
                            }
                        }
                    }
                    &.active {
                        opacity: 1;
                        margin-top: 10px;
                        visibility: visible;
                    }
                }
            }
        }
        &.is-sticky {
            position: fixed;
            top: 0;
            left: 250px;
            z-index: 999;
            box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.07);
            background: $white-color !important;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            width: calc(100% - 250px);
            padding: {
                left: 30px;
                right: 30px;
                top: 17px;
                bottom: 17px;
            };
            &.toggle-navbar-area {
                left: 55px;
                width: calc(100% - 55px);
            }
        }
    }
}
@keyframes ring {
    0% {
        transform: rotateZ(0);
    }
    1% {
        transform: rotateZ(30deg);
    }
    3% {
        transform: rotateZ(-28deg);
    }
    5% {
        transform: rotateZ(34deg);
    }
    7% {
        transform: rotateZ(-32deg);
    }
    9% {
        transform: rotateZ(30deg);
    }
    11% {
        transform: rotateZ(-28deg);
    }
    13% {
        transform: rotateZ(26deg);
    }
    15% {
        transform: rotateZ(-24deg);
    }
    17% {
        transform: rotateZ(22deg);
    }
    19% {
        transform: rotateZ(-20deg);
    }
    21% {
        transform: rotateZ(18deg);
    }
    23% {
        transform: rotateZ(-16deg);
    }
    25% {
        transform: rotateZ(14deg);
    }
    27% {
        transform: rotateZ(-12deg);
    }
    29% {
        transform: rotateZ(10deg);
    }
    31% {
        transform: rotateZ(-8deg);
    }
    33% {
        transform: rotateZ(6deg);
    }
    35% {
        transform: rotateZ(-4deg);
    }
    37% {
        transform: rotateZ(2deg);
    }
    39% {
        transform: rotateZ(-1deg);
    }
    41% {
        transform: rotateZ(1deg);
    }
    43% {
        transform: rotateZ(0);
    }
    100% {
        transform: rotateZ(0);
    }
}
@keyframes pulse-secondary {
    0% {
        box-shadow: 0 0 0 rgba(8, 141, 211, 0.6);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(8, 141, 211, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(8, 141, 211, 0);
    }
}
@keyframes pulse-primary {
    0% {
        box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(41, 98, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(41, 98, 255, 0);
    }
}

/*================================================
Main Content CSS
=================================================*/
.main-content {
    transition: $transition;
    overflow: hidden;
    min-height: 100vh;
    padding: {
        right: 30px;
        left: 280px;
    };
    &.hide-sidemenu-area {
        padding-left: 85px;
    }
}
.breadcrumb-area {
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
    background-color: $white-color;
    padding: 20px 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: {
        top: 20px;
        bottom: 30px;
    };
    h1 {
        margin-bottom: 0;
        position: relative;
        color: $black-color;
        padding-right: 12px;
        font: {
            size: 20px;
            weight: 700;
        };
        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 16px;
            width: 1px;
            background: #cecece;
        }
    }
    .breadcrumb {
        background-color: transparent;
        border-radius: 0;
        margin: {
            bottom: 0;
        };
        padding: {
            left: 12px;
            right: 0;
            bottom: 0;
            top: 0;
        };
        .item {
            color: #606060;
            margin-right: 25px;
            position: relative;
            font: {
                size: $font-size;
                family: $font-family;
            };
            a {
                display: inline-block;
                color: $main-color;

                &:hover {
                    color: $main-color;
                }
                i {
                    font-size: 18px;
                }
            }
            &::before {
                content: "\003e";
                position: absolute;
                right: -21px;
                top: 2px;
                line-height: 18px;
                color: #606060;
                font: {
                    family: "boxicons" !important;
                    weight: normal;
                    style: normal;
                    variant: normal;
                    size: 18px;
                };
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
    .btn {
        float:right;
    }
}
.web-analytics {
    .apexcharts-legend {
        &.apexcharts-align-center {
            &.position-bottom {
                bottom: 0 !important;
                margin-top: 15px;
            }
        }
    }
    .apexcharts-toolbar {
        display: none !important;
    }
}
/*start-stats-box-css*/
.stats-card-box {
    margin-bottom: 30px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    padding: 25px 25px 25px 110px;
    border-radius: 5px;
    position: relative;

    .icon-box {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: $main-color;
        position: absolute;
        left: 25px;
        top: 48%;
        transform: translateY(-48%);
        box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .15);
        text-align: center;
        font-size: 35px;
        color: $white-color;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            &.bx-paper-plane {
                top: 45%;
                transform: translateY(-45%);
                left: -3px;
            }
        }
    }
    .sub-title {
        color: #a3a3a3;
        display: block;
        margin-bottom: 5px;
        font: {
            size: 17px;
            weight: 400;
        };
    }
    h3 {
        margin-bottom: 0;
        font: {
            size: 35px;
            weight: 700;
        };
    }
}
.col-lg-3 {
    &:nth-child(2) {
        .stats-card-box {
            .icon-box {
                background-color: #13bb37;
                box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, .15);
            }
            .progress-list {        
                .bar-inner {
                    .bar {
                        background: #13bb37;
                    }
                }
            }
        }
    }
    &:nth-child(3) {
        .stats-card-box {
            .icon-box {
                background-color: #ff4b00;
                box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, .15);
            }
            .progress-list {        
                .bar-inner {
                    .bar {
                        background: #ff4b00;
                    }
                }
            }
        }
    }
    &:nth-child(4) {
        .stats-card-box {
            .icon-box {
                background-color: #4788ff;
                box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, .15);
            }
            .progress-list {        
                .bar-inner {
                    .bar {
                        background: #4788ff;
                    }
                }
            }
        }
    }
}
.ecommerce-stats-area {
    margin-bottom: 30px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    border-radius: 5px;
    padding: 30px 25px;

    .row {
        margin: {
            left: 0;
            right: 0;
        };
        .col-lg-3 {
            padding: {
                left: 0;
                right: 0;
            };
            &:nth-child(2) {
                .single-stats-card-box {
                    .icon {
                        background-color: #13bb37;
                        box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, .15);
                    }
                }
            }
            &:nth-child(3) {
                .single-stats-card-box {
                    .icon {
                        background-color: #ff4b00;
                        box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, .15);
                    }
                }
            }
            &:nth-child(4) {
                .single-stats-card-box {
                    border-right: none;

                    .icon {
                        background-color: #4788ff;
                        box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, .15);
                    }
                }
            }
        }
    }
}
.single-stats-card-box {
    padding-left: 90px;
    position: relative;

    .icon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: $main-color;
        position: absolute;
        left: 0;
        top: 46%;
        transform: translateY(-46%);
        box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .15);
        text-align: center;
        font-size: 38px;
        color: $white-color;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            &.bx-paper-plane {
                top: 45%;
                transform: translateY(-45%);
                left: -3px;
            }
        }
    }
    .sub-title {
        color: #a3a3a3;
        display: block;
        margin-bottom: 5px;
        font: {
            size: 15px;
            weight: 400;
        };
    }
    h3 {
        margin-bottom: 0;
        color: $main-color;
        position: relative;
        font: {
            size: 30px;
            weight: 700;
        };
        .badge {
            line-height: initial;
            border-radius: 0;
            color: #13bb37;
            background-color: rgba(49, 203, 114, 0.18);
            padding: 2px 4px 1px;
            border-radius: 5px;
            position: relative;
            bottom: 3px;
            font: {
                size: 11px;
                weight: 400;
            };
            &.badge-red {
                background-color: rgba(255, 91, 91, 0.18);
                color: #ff4b00;
            }
            i {
                display: inline-block;
                margin-right: -2px;
            }
        }
    }
}
/*end-stats-box-css*/

/*start-card-box-css*/
.card {
    background-color: $white-color;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    border: none;
    border-radius: 5px;
    padding: 25px;

    .card-header {
        background-color: $white-color;
        border-radius: 0;
        margin-bottom: 25px;
        padding: 0;
        border: none;

        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 700;
            };
        }
        .dropdown {
            .dropdown-toggle {
                padding: 0;
                border: none;
                background-color: transparent;
                color: #727E8C;
                font-size: 22px;
                line-height: 15px;

                &::after {
                    display: none;
                }
            }
            .dropdown-menu {
                box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05);
                background-color: $white-color;
                border: none;
                border-radius: 0;
                margin-top: 35px;
                display: block;
                opacity: 0;
                visibility: hidden;
                transition: .3s;
                transform: unset !important;
                left: auto !important;
                right: 0;

                &.show {
                    margin-top: 25px;
                    opacity: 1;
                    visibility: visible;
                }
                .dropdown-item {
                    color: $black-color;
                    padding: 5px 15px 5px 36px;
                    position: relative;
                    font: {
                        size: 14px;
                    };
                    i {
                        color: #686868;
                        transition: $transition;
                        position: absolute;
                        left: 15px;
                        top: 7px;
                        font: {
                            size: 16px;
                        };
                    }
                    &.active, &:active {
                        color: $black-color;
                        background-color: transparent;
                        background-color: #f8f9fa;
                    }
                    &:hover {
                        i {
                            transform: rotateY(-180deg);
                        }
                    }
                }
            }
        }
    }
    .card-body {
        padding: 0;

        .youtube-video {
            iframe {
                width: 100%;
                border: none;
                height: 435px;
            }
        }
        &.border-primary {
            border-width: 1px;
            border-style: solid;
        }
        &.border-secondary {
            border-width: 1px;
            border-style: solid;
        }
        &.border-success {
            border-width: 1px;
            border-style: solid;
        }
        &.border-danger {
            border-width: 1px;
            border-style: solid;
        }
        &.border-warning {
            border-width: 1px;
            border-style: solid;
        }
        &.border-info {
            border-width: 1px;
            border-style: solid;
        }
        &.border-light {
            border-width: 1px;
            border-style: solid;
        }
        &.border-dark {
            border-width: 1px;
            border-style: solid;
        }
    }
}
.widget-todo-list {
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            position: relative;
            transition: $transition;
            background-color: rgba(0, 0, 0, 0.02);
            padding: {
                left: 65px;
                right: 265px;
                top: 25px;
                bottom: 25px;
            };
            .icon {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 35px;
                box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, 0.10);
                height: 35px;
                border-radius: 50%;
                background-color: $main-color;
                text-align: center;
                font-size: 20px;
                color: $white-color;
                line-height: 36px;
            }
            .todo-item-title {
                position: relative;

                h3 {
                    margin-bottom: 0;
                    color: #777777;
                    font: {
                        size: 16px;
                        weight: normal;
                    };
                    a {
                        color: $main-color;
                        text-decoration: none;
                    }
                }
            }
            .todo-item-action {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;

                p {
                    display: inline-block;
                    color: #777777;
                    line-height: 1;
                    margin: {
                        bottom: 0;
                        right: 50px;
                    };
                    i {
                        color: $main-color;
                        position: relative;
                        top: 1px;
                    }
                }
                a {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 32px;
                    border-radius: 3px;
                    background-color: #c6e8cd;
                    color: #13bb37;
                    text-align: center;
                    margin-left: 5px;

                    &:hover {
                        background-color: #13bb37;
                        color: $white-color;
                    }
                    &.delete {
                        background-color: #f9d0d9 !important;
                        color: #e1000a !important;

                        &:hover {
                            background-color: #e1000a !important;
                            color: $white-color !important;
                        }
                    }
                }
            }
            &:nth-of-type(odd) {
                background-color: rgba(0, 0, 0, .04);

                .icon {
                    background: #5ECFB1;
                    color: $white-color;
                }
                .todo-item-action {
                    a {
                        background-color: #d1def5;
                        color: #4788ff;
    
                        &:hover {
                            background-color: #4788ff;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}
.cbx {
    margin: auto;
    -webkit-user-select:none;
    user-select: none;
    cursor: pointer;

    span {
        display: inline-block;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);

        &:first-child {
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 3px;
            transform: scale(1);
            vertical-align: middle;
            border: 1px solid #DFE3E7;
            transition: all 0.2s ease;

            svg {
                position: absolute;
                top: 3px;
                left: 2px;
                fill: none;
                stroke: $white-color;
                stroke-width: 2;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-dasharray: 16px;
                stroke-dashoffset: 16px;
                transition: all 0.3s ease;
                transition-delay: 0.1s;
                transform: translate3d(0, 0, 0);
            }
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background: $main-color;
                display: block;
                transform: scale(0);
                opacity: 1;
                border-radius: 50%;
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: $main-color;
                    }
                }
            }
        }
    }
    .inp-cbx:checked + & span:first-child {
        background: $main-color;
        border-color: $main-color;
        animation: wave 0.4s ease;
    }
    .inp-cbx:checked + & span:first-child svg {
        stroke-dashoffset: 0;
    }
    .inp-cbx:checked + & span:first-child:before {
        transform: scale(3.5);
        opacity: 0;
        transition: all 0.6s ease;
    }
    &.active {
        .inp-cbx + & span:first-child {
            background: $main-color;
            border-color: $main-color;
            animation: wave 0.4s ease;
        }
        .inp-cbx + & span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx + & span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
    }
}
@keyframes wave {
    50% {
        transform: scale(0.9);
    }
}
.recent-orders-box {
    table {
        margin-bottom: 0;

        thead {
            th {
                text-align: center;
                white-space: nowrap;
                padding: 0 15px 15px;
                border: {
                    bottom: 2px solid #f6f6f7 !important;
                    top: none;
                };
                font: {
                    size: $font-size;
                };
                &:first-child {
                    text-align: left;
                    padding-left: 0;
                }
            }
        }
        tbody {
            tr {
                transition: $transition;

                td {
                    text-align: center;
                    padding: 15px;
                    vertical-align: middle;
                    white-space: nowrap;
                    border: {
                        bottom: 1px solid #f6f6f7;
                        top: none;
                    };
                    &:first-child {
                        text-align: left;
                        padding-left: 0;
                    }
                    font: {
                        size: 14.4px;
                    };
                    .badge {
                        padding: 0;
                        background-color: transparent !important;
                        color: $black-color;
                        font: {
                            weight: 600;
                            size: 14.4px;
                        };
                        &.badge-primary {
                            color: #13bb37;
                        }
                        &.badge-success {
                            color: #f59d03;
                        }
                        &.badge-danger {
                            color: #ff5c75;
                        }
                    }
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                    &.name {
                        font-weight: 700;
                    }
                    button {
                        display: inline-block;
                        background-color: $main-color;
                        color: $white-color;
                        padding: 7px 18px;
                        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
                        border: none;
                        border-radius: 3px;
                        transition: $transition;
                        font: {
                            size: 14px;
                            weight: 700;
                        };
                        &:hover {
                            transform: translateY(-5px);
                        }
                    }
                }
                &:last-child {
                    td {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .table > :not(:first-child) {
        border-top: none;
    }
}
.ml-auto {
    margin-left: auto;
}
.mr-2, .mx-2 {
    margin-right: .5rem;
}
.ml-3, .mx-3 {
    margin-left: 1rem;
}
.mr-3, .mx-3 {
    margin-right: 1rem!important;
}
.btn-block {
    display: block;
    width: 100%;
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}
.badge-primary {
    color: #fff;
    background-color: #007bff;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
.bookings-listing-box {
    .card-body {
        margin: {
            left: -25px;
            right: -25px;
        };
    }
    table {
        margin-bottom: 0;

        thead {
            th {
                white-space: nowrap;
                vertical-align: middle;
                color: $black-color;
                border: {
                    bottom: 1px solid #eeeeee !important;
                    top: 1px solid #eeeeee;
                    right: 1px solid #eeeeee;
                };
                padding: {
                    top: 10px;
                    left: 25px;
                    right: 25px;
                    bottom: 9px;
                };
                font: {
                    size: 18px;
                    weight: 600;
                };
            }
        }
        tbody {
            td {
                white-space: nowrap;
                vertical-align: middle;
                border: {
                    bottom: 1px solid #eeeeee;
                    right: 1px solid #eeeeee;
                    top: 1px solid #eeeeee;
                };
                padding: {
                    top: 30px;
                    left: 25px;
                    right: 25px;
                    bottom: 30px;
                };
                &.name {
                    img {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                    }
                    span {
                        display: inline-block;
                        margin-left: 18px;
                        font: {
                            size: 18px;
                            weight: 700;
                        };
                    }
                }
                &.details {
                    h3 {
                        margin-bottom: 25px;
                        font: {
                            size: 22px;
                            weight: 700;
                        };
                    }
                    ul {
                        padding-left: 0;
                        list-style-type: none;
                        margin: {
                            bottom: 0;
                        };
                        li {
                            margin-top: 15px;
                            color: #777777;

                            span {
                                display: block;
                                text-transform: uppercase;
                                color: $black-color;
                                font-weight: 600;
                                margin-bottom: 6px;

                                i {
                                    color: $main-color;
                                }
                            }
                            a {
                                color: #777777;
                                text-decoration: none;
                                display: inline-block;
                                transition: $transition;

                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .table > :not(:first-child) {
        border-top: none;
    }
}
.visitor-reviews-box {
    .user-review {
        border-bottom: 1px solid #f3f3f3;
        padding: 20px 125px 20px 110px;
        position: relative;

        img {
            position: absolute;
            left: 0;
            top: 20px;
            width: 90px;
            height: 90px;
            border-radius: 5px;
        }
        .sub-comment {
            margin-bottom: 10px;
            font: {
                size: 17px;
                weight: 600;
            };
        }
        .review-rating {
            display: block;
            margin-bottom: 10px;

            .review-stars {
                display: inline-block;

                i {
                    color: #f2b827;
                    font-size: 18px;
                    display: inline-block;
                }
            }
            span {
                color: $black-color;
                position: relative;
                top: -2px;
                font-weight: 600;
                margin-left: 5px;
            }
        }
        .btn-box {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.new-customer-box {
    table {
        margin-bottom: 0;

        thead {
            th {
                text-align: center;
                border: {
                    bottom: 2px solid #f6f6f7;
                    top: none;
                };
                padding: 0 15px 14px;
                font: {
                    size: $font-size;
                };
                &:first-child {
                    text-align: left;
                    padding-left: 0;
                }
            }
        }
        tbody {
            tr {
                transition: $transition;

                td {
                    text-align: center;
                    padding: 13px 15px;
                    vertical-align: middle;
                    border: {
                        bottom: 1px solid #f6f6f7;
                        top: none;
                    };
                    white-space: nowrap;
                    &:first-child {
                        text-align: left;
                        padding-left: 0;
                    }
                    font: {
                        size: 14.4px;
                    };
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                    .text-success {
                        font-size: 16px;
                    }
                    .text-danger {
                        font-size: 16px;
                    }
                    .text-warning {
                        font-size: 17px;
                    }
                    &.name {
                        font-weight: 700;
                    }
                }
                &:last-child {
                    td {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .table > :not(:first-child) {
        border-top: none;
    }
}
.greetings-card-box {
    .image {
        margin-right: 20px;
        margin-top: 60px;
    }
    .content {
        margin-left: 10px;
        text-align: center;

        .icon {
            text-align: center;
            width: 65px;
            height: 65px;
            position: relative;
            border-radius: 50%;
            background-color: #fcdedf;
            color: $main-color;
            font-size: 35px;
            transition: $transition;
            margin: {
                left: auto;
                right: auto;
            };
            i {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                margin: {
                    left: auto;
                    right: auto;
                };
            }
        }
        h3 {
            margin-bottom: 10px;
            font: {
                size: 25px;
                weight: 700;
            };
        }
        p {
            color: #727E8C;
        }
    }
    &:hover {
        .content {
            .icon {
                background-color: $main-color;
                color: #fcdedf;
            }
        } 
    }
}
/*end-card-box-css*/

/*start-welcome-area*/
.welcome-area {
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    border-radius: 5px;
    padding: {
        left: 25px;
    };
    margin: {
        top: 20px;
        bottom: 30px;
    };
}
.welcome-content {
    h1 {
        font: {
            size: 35px;
            weight: 700;
        };
    }
    p {
        color: #777777;
        font: {
            size: 18px;
        };
    }
}
.welcome-img {
    text-align: center;
    padding-top: 20px;

    img {
        width: 100%;
    }
}
/*end-welcome-area*/

/*================================================
App Email CSS
=================================================*/
.email-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            .sidebar-content {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
                padding: 25px;
                width: 260px;
                height: calc(100vh - 201px);

                .email-menu {
                    .btn {
                        border: none;
                        padding: 12px 20px 11px;
                        position: relative;
                        transition: $transition;
                        font: {
                            size: $font-size;
                            weight: 600;
                        };
                        i {
                            font-size: 20px;
                            position: absolute;
                            margin: {
                                left: -23px;
                            };
                        }
                    }
                    .btn-primary {
                        color: $white-color;
                        background-color: $main-color;
                    
                        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .2);
                        }
                        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>&.dropdown-toggle:focus {
                            box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .2);
                        }
                        &:hover, &:focus {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .2);
                        }
                    }
                    .list-group-messages {
                        .list-group-item {
                            text-decoration: none;
                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 29px;
                            };
                            margin-bottom: 16px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                            i {
                                position: absolute;
                                left: 0;
                                transition: $transition;
                                top: 0;
                                font-size: 22px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }
                            .badge {
                                transition: $transition;
                                margin-left: 4px;
                                position: relative;
                                top: -1px;
                                padding: 4px 6px 4px 6px;
                            }
                            &:hover {
                                color: $main-color;

                                i {
                                    transform: rotateY(-180deg);
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    .list-group-label {
                        letter-spacing: 1px;
                        color: #828D99;
                        margin: {
                            top: 25px;
                            bottom: 15px;
                        };
                        font: {
                            size: 14px;
                            weight: 600;
                        };
                    }
                    .list-group-labels {
                        .list-group-item {
                            text-decoration: none;
                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 18px;
                            };
                            margin-bottom: 12px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                            &::before {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                border: 2px solid $main-color;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }
                            &:hover {
                                color: $main-color;
                            }
                            &:nth-child(2) {
                                &::before {
                                    border-color: #f79921;
                                }
                            }
                            &:nth-child(3) {
                                &::before {
                                    border-color: #1ebe40;
                                }
                            }
                            &:nth-child(4) {
                                &::before {
                                    border-color: #40fefe;
                                }
                            }
                            &:nth-child(5) {
                                &::before {
                                    border-color: #707070;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .content-right {
        float: right;
        width: calc(100% - 290px);

        .email-list-wrapper {
            .email-list {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
                
                .email-list-header {
                    padding: 15px 20px;
                    border-bottom: 1px solid #e8ebf1;

                    .header-left {
                        position: relative;
                        top: 3px;

                        .checkbox {
                            position: relative;
                            top: -2px;
                        }
                        .btn-icon {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 3px;
    
                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 47%;
                                transform: translateY(-47%);
                            }
                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }
                            &::after {
                                display: none;
                            }
                        }
                        .dropdown {
                            .dropdown-menu {
                                box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05);
                                background-color: $white-color;
                                border: none;
                                border-radius: 0;
                                margin-top: 35px;
                                display: block;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                transform: unset !important;
                
                                &.show {
                                    margin-top: 45px;
                                    opacity: 1;
                                    visibility: visible;
                                }
                                .dropdown-item {
                                    color: $black-color;
                                    padding: 5px 15px 5px 36px;
                                    position: relative;
                                    font: {
                                        size: 14px;
                                    };
                                    i {
                                        color: #686868;
                                        transition: $transition;
                                        position: absolute;
                                        left: 15px;
                                        top: 7px;
                                        font: {
                                            size: 16px;
                                        };
                                    }
                                    &.active, &:active {
                                        color: $black-color;
                                        background-color: transparent;
                                        background-color: #f8f9fa;
                                    }
                                    &:hover {
                                        i {
                                            transform: rotateY(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .header-right {
                        position: relative;

                        label {
                            position: absolute;
                            left: 15px;
                            color: #71738d;
                            margin-bottom: 0;
                            top: 14px;
                        }
                        .form-control {
                            height: 45px;
                            border-radius: 30px;
                            background-color: #eef5f9;
                            border: none;
                            color: $black-color;
                            padding: 2px 15px 0 39px;
                            transition: $transition;
                            box-shadow: unset;
                            font: {
                                size: 14px;
                                weight: 400;
                            };
                            &:focus {
                                box-shadow: unset;
                                background-color: #eef5f9;

                                &::placeholder {
                                    color: transparent;
                                }
                            }
                            &::placeholder {
                                color: #a8a8a8;
                                transition: $transition;
                            }
                        }
                    }
                }
                .list-wrapper {
                    height: calc(100vh - 343px);
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;

                    .email-list-item {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #e8ebf1;
                        padding: 16.1px 20px;
                        width: 100%;
                        position: relative;
                        background-color: $white-color;
                        font-size: $font-size;
                        transition: $transition;

                        &:last-child {
                            border-bottom: none;
                        }
                        .email-list-actions {
                            .checkbox {
                                position: relative;
                                top: 2px;
                            }
                            .favorite {
                                display: inline-block;
                                font-size: 20px;
                                cursor: pointer;
                                color: #C7CFD6;
                                margin: {
                                    top: 8px;
                                    bottom: -5px;
                                };
                                &.active {
                                    color: #FDAC41;

                                    i {
                                        &::before {
                                            content: "\ed99";
                                        }
                                    }
                                }
                            }
                        }
                        .email-list-detail {
                            text-decoration: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-grow: 1;
                            flex-wrap: wrap;
                            position: relative;

                            div {
                                position: relative;
                                padding-left: 65px;
                                margin-top: -2px;
                            }
                            img {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                            }
                            .from {
                                color: $black-color;
                                font: {
                                    size: 16px;
                                    weight: 700;
                                };
                            }
                            .msg {
                                color: #71738d;
                                font-size: $font-size;
                            }
                            .date {
                                color: $black-color;
                                font-weight: 600;
                            }
                        }
                        &.read {
                            background-color: #f7f7f7;

                            .email-list-detail {
                                .from {
                                    color: #616161;
                                }
                                .msg {
                                    color: #71738d;
                                }
                                .date {
                                    color: #616161;
                                }
                            }
                        }
                        &:hover {
                            box-shadow: 0 0 10px 0 rgba(58, 70, 93, .25);
                            transition: $transition;
                            z-index: 1;
                        }
                    }
                }
                .email-list-footer {
                    border-top: 1px solid #e8ebf1;
                    padding: 15px 20px;

                    p {
                        color: #71738d;
                    }
                    .email-pagination-btn {
                        margin-bottom: -5px;

                        button {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 5px;
                            margin-left: 5px;

                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }
                            &.email-pagination-prev {
                                border-radius: 5px 0 0 5px;
                            }
                            &.email-pagination-next {
                                border-radius: 0 5px 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
App Email Read CSS
=================================================*/
.email-read-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            .sidebar-content {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
                padding: 25px;
                width: 260px;
                height: calc(100vh - 201px);

                .email-read-menu {
                    .btn {
                        border: none;
                        padding: 12px 20px 11px;
                        position: relative;
                        transition: $transition;
                        font: {
                            size: $font-size;
                            weight: 600;
                        };
                        i {
                            font-size: 20px;
                            position: absolute;
                            margin: {
                                left: -23px;
                            };
                        }
                    }
                    .btn-primary {
                        color: $white-color;
                        background-color: $main-color;
                    
                        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .2);
                        }
                        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>&.dropdown-toggle:focus {
                            box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .2);
                        }
                        &:hover, &:focus {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .2);
                        }
                    }
                    .list-group-messages {
                        .list-group-item {
                            text-decoration: none;
                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 29px;
                            };
                            margin-bottom: 16px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                            i {
                                position: absolute;
                                left: 0;
                                transition: $transition;
                                top: 0;
                                font-size: 22px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }
                            .badge {
                                transition: $transition;
                                margin-left: 4px;
                                position: relative;
                                top: -1px;
                                padding: 4px 6px 4px 6px;
                            }
                            &:hover {
                                color: $main-color;

                                i {
                                    transform: rotateY(-180deg);
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    .list-group-label {
                        letter-spacing: 1px;
                        color: #828D99;
                        margin: {
                            top: 25px;
                            bottom: 15px;
                        };
                        font: {
                            size: 14px;
                            weight: 600;
                        };
                    }
                    .list-group-labels {
                        .list-group-item {
                            text-decoration: none;
                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 18px;
                            };
                            margin-bottom: 12px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                            &::before {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                border: 2px solid $main-color;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }
                            &:hover {
                                color: $main-color;
                            }
                            &:nth-child(2) {
                                &::before {
                                    border-color: #f79921;
                                }
                            }
                            &:nth-child(3) {
                                &::before {
                                    border-color: #1ebe40;
                                }
                            }
                            &:nth-child(4) {
                                &::before {
                                    border-color: #40fefe;
                                }
                            }
                            &:nth-child(5) {
                                &::before {
                                    border-color: #707070;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .content-right {
        float: right;
        width: calc(100% - 290px);

        .email-read-list-wrapper {
            .email-read-list {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
                
                .email-read-list-header {
                    padding: 15px 20px;
                    border-bottom: 1px solid #e8ebf1;

                    .header-left {
                        position: relative;
                        top: 1px;

                        .go-back {
                            color: #727E8C;
                            font-size: 25px;
                            line-height: 0;
                            position: relative;
                            top: -1px;

                            &:hover {
                                color: $main-color;
                            }
                        }
                        h3 {
                            font-size: 18px;

                            .badge {
                                color: $white-color;
                                border-radius: 30px;
                                padding: 5px 15px;
                                font: {
                                    weight: 500;
                                };
                            }
                        }
                    }
                    .header-right {
                        position: relative;
                        top: 3px;

                        .btn-icon {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 3px;
    
                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 47%;
                                transform: translateY(-47%);
                            }
                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }
                            &::after {
                                display: none;
                            }
                        }
                        .dropdown {
                            .dropdown-menu {
                                box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05);
                                background-color: $white-color;
                                border: none;
                                border-radius: 0;
                                margin-top: 35px;
                                display: block;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                transform: unset !important;
                                left: auto !important;
                                right: 0 !important;
                
                                &.show {
                                    margin-top: 45px;
                                    opacity: 1;
                                    visibility: visible;
                                }
                                .dropdown-item {
                                    color: $black-color;
                                    padding: 5px 15px 5px 36px;
                                    position: relative;
                                    font: {
                                        size: 14px;
                                    };
                                    i {
                                        color: #686868;
                                        transition: $transition;
                                        position: absolute;
                                        left: 15px;
                                        top: 7px;
                                        font: {
                                            size: 16px;
                                        };
                                    }
                                    &.active, &:active {
                                        color: $black-color;
                                        background-color: transparent;
                                        background-color: #f8f9fa;
                                    }
                                    &:hover {
                                        i {
                                            transform: rotateY(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .email-read-scroll-area {
                    height: calc(100vh - 338px);
                }
                .list-wrapper {
                    padding: 20px;
                    margin-bottom: 0;
                    list-style-type: none;

                    .email-read-list-item {
                        border-radius: 5px;
                        border: 1px solid $white-color;
                        padding: 15px 20px;
                        width: 100%;
                        position: relative;
                        font-size: $font-size;
                        transition: $transition;
                        margin-bottom: 15px;
                        box-shadow: 0 0 18px rgba(25, 42, 70, .13);
                        background-color: $white-color;

                        &:last-child {
                            margin-bottom: 0;
                        }
                        .email-read-list-detail {
                            text-decoration: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-grow: 1;
                            flex-wrap: wrap;
                            cursor: pointer;
                            position: relative;

                            .user-information {
                                position: relative;
                                padding-left: 65px;
                                margin-top: -2px;
                            }
                            img {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                            }
                            .name {
                                color: $black-color;
                                font: {
                                    size: 16px;
                                    weight: 700;
                                };
                                .email {
                                    color: #727E8C;
                                    font: {
                                        weight: normal;
                                        size: $font-size;
                                    };
                                }
                            }
                            .to {
                                color: #71738d;
                                font-size: 13px;
                            }
                            .email-information {
                                .date {
                                    color: #727E8C;
                                    font-weight: 500;
                                }
                                .favorite {
                                    display: inline-block;
                                    font-size: 20px;
                                    cursor: pointer;
                                    color: #C7CFD6;
                                    margin: {
                                        left: 12px;
                                        right: 8px;
                                    };
                                    &.active {
                                        color: #FDAC41;
    
                                        i {
                                            &::before {
                                                content: "\ed99";
                                            }
                                        }
                                    }
                                }
                                .dropdown {
                                    .dropdown-toggle {
                                        padding: 0;
                                        border: none;
                                        background-color: transparent;
                                        color: #727E8C;
                                        font-size: 22px;
                                        line-height: 15px;
                        
                                        &::after {
                                            display: none;
                                        }
                                    }
                                    .dropdown-menu {
                                        box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05);
                                        background-color: $white-color;
                                        border: none;
                                        border-radius: 0;
                                        margin-top: 35px;
                                        display: block;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                        transform: unset !important;
                                        left: auto !important;
                                        right: 0;
                        
                                        &.show {
                                            margin-top: 25px;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                        .dropdown-item {
                                            color: $black-color;
                                            padding: 5px 15px 5px 36px;
                                            position: relative;
                                            font: {
                                                size: 14px;
                                            };
                                            i {
                                                color: #686868;
                                                transition: $transition;
                                                position: absolute;
                                                left: 15px;
                                                top: 7px;
                                                font: {
                                                    size: 16px;
                                                };
                                            }
                                            &.active, &:active {
                                                color: $black-color;
                                                background-color: transparent;
                                                background-color: #f8f9fa;
                                            }
                                            &:hover {
                                                i {
                                                    transform: rotateY(-180deg);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .email-read-list-content {
                            margin-top: 25px;

                            p {
                                margin-bottom: 10px;
                                color: #727E8C;
                            }
                            .email-read-list-footer {
                                border-top: 1px solid #e8ebf1;
                                padding: {
                                    top: 18px;
                                    left: 20px;
                                    right: 20px;
                                };
                                margin: {
                                    left: -20px;
                                    top: 20px;
                                    right: -20px;
                                };
                                label {
                                    font-size: 14px;
                                }
                                ul {
                                    li {
                                        margin-bottom: 5px;

                                        a {
                                            color: #727E8C;
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .email-reply-list-item {
                        margin: {
                            top: 30px;
                            bottom: 8px;
                        };
                        a {
                            box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
                            background-color: $white-color;
                            border: 1px solid #eeeeee;
                            padding: 10px 30px;
                            border-radius: 30px;
                            margin-right: 10px;
                            text-decoration: none;
                            color: $black-color;
                            font: {
                                size: $font-size;
                                weight: 700;
                            };
                            &:last-child {
                                margin-right: 0;
                            }
                            i {
                                font-size: 20px;
                                position: relative;
                                top: 3px;
                            }
                            &:hover {
                                color: $white-color;
                                border-color: $main-color;
                                background-color: $main-color;
                            }
                        }
                    }
                }
                .email-list-footer {
                    border-top: 1px solid #e8ebf1;
                    padding: 15px 20px;

                    p {
                        color: #71738d;
                    }
                    .email-pagination-btn {
                        margin-bottom: -5px;

                        button {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 5px;
                            margin-left: 5px;

                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }
                            &.email-pagination-prev {
                                border-radius: 5px 0 0 5px;
                            }
                            &.email-pagination-next {
                                border-radius: 0 5px 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
App Email Compose CSS
=================================================*/
.email-compose-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            .sidebar-content {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
                padding: 25px;
                width: 260px;

                .email-compose-menu {
                    .list-group-messages {
                        .list-group-item {
                            text-decoration: none;
                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 29px;
                            };
                            margin-bottom: 16px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                            i {
                                position: absolute;
                                left: 0;
                                transition: $transition;
                                top: 0;
                                font-size: 22px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }
                            .badge {
                                transition: $transition;
                                margin-left: 4px;
                                position: relative;
                                top: -1px;
                                padding: 4px 6px 4px 6px;
                            }
                            &:hover {
                                color: $main-color;

                                i {
                                    transform: rotateY(-180deg);
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    .list-group-label {
                        letter-spacing: 1px;
                        color: #828D99;
                        margin: {
                            top: 25px;
                            bottom: 15px;
                        };
                        font: {
                            size: 14px;
                            weight: 600;
                        };
                    }
                    .list-group-labels {
                        .list-group-item {
                            text-decoration: none;
                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 18px;
                            };
                            margin-bottom: 12px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                            &::before {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                border: 2px solid $main-color;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }
                            &:hover {
                                color: $main-color;
                            }
                            &:nth-child(2) {
                                &::before {
                                    border-color: #f79921;
                                }
                            }
                            &:nth-child(3) {
                                &::before {
                                    border-color: #1ebe40;
                                }
                            }
                            &:nth-child(4) {
                                &::before {
                                    border-color: #40fefe;
                                }
                            }
                            &:nth-child(5) {
                                &::before {
                                    border-color: #707070;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .content-right {
        float: right;
        width: calc(100% - 290px);

        .email-compose-list-wrapper {
            background-color: $white-color;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
            border: none;
            border-radius: 5px;
            padding: 25px;

            h3 {
                margin-bottom: 20px;
                font: {
                    size: 18px;
                    weight: 700;
                };
            }
            form {
                .card {
                    background-color: transparent;
                    box-shadow: unset;
                    border-radius: 5px;
                    padding: 0;

                    .card-header {
                        background-color: transparent;
                        margin-bottom: 0;
                    }
                }
                .form-control {
                    height: 42px;

                    &:focus {
                        border-color: #b1bbc4;
                    }
                }
                .note-editor.note-airframe, .note-editor.note-frame {
                    border-color: #ced4da;
                }
                .note-popover .popover-content>.note-btn-group, .note-toolbar>.note-btn-group {
                    margin-top: -1px;
                }
                .form-group {
                    .btn {
                        transition: $transition;
                        border-radius: 2px;
                        box-shadow: unset !important;
                        padding: 10px 25px 9.5px;
                        font: {
                            size: $font-size;
                            weight: 700;
                        };
                        i {
                            position: relative;
                            top: 1px;
                        }
                        &.btn-success {
                            background-color: #3578e5;
                            color: $white-color;
                            border-color: #3578e5;
    
                            &:hover, &:focus {
                                background-color: #3578e5;
                                color: $white-color;
                                border-color: #3578e5;
                                box-shadow: 1px 5px 24px 0 rgba(102, 88, 221, 0.25) !important;
                            }
                        }
                        &.btn-primary {
                            background-color: $main-color;
                            color: $white-color;
                            border-color: $main-color;
    
                            &:hover, &:focus {
                                background-color: #e33e45;
                                color: $white-color;
                                border-color: #e33e45;
                                box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.25) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
App Todo CSS
=================================================*/
.todo-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            .sidebar-content {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
                padding: 25px;
                width: 260px;
                height: calc(100vh - 201px);

                .todo-menu {
                    .btn {
                        border: none;
                        padding: 12px 20px 11px;
                        position: relative;
                        transition: $transition;
                        font: {
                            size: $font-size;
                            weight: 600;
                        };
                        i {
                            font-size: 20px;
                            position: absolute;
                            margin: {
                                left: -23px;
                            };
                        }
                    }
                    .btn-primary {
                        color: $white-color;
                        background-color: $main-color;
                    
                        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .2);
                        }
                        &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>&.dropdown-toggle:focus {
                            box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .2);
                        }
                        &:hover, &:focus {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, .2);
                        }
                    }
                    .list-group-messages {
                        .list-group-item {
                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 29px;
                            };
                            text-decoration: none;
                            margin-bottom: 16px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                            i {
                                position: absolute;
                                left: 0;
                                transition: $transition;
                                top: 0;
                                font-size: 22px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }
                            .badge {
                                transition: $transition;
                                margin-left: 4px;
                                position: relative;
                                top: -1px;
                                padding: 4px 6px 4px 6px;
                            }
                            &:hover {
                                color: $main-color;

                                i {
                                    transform: rotateY(-180deg);
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    .list-group-label {
                        letter-spacing: 1px;
                        color: #828D99;
                        margin: {
                            top: 25px;
                            bottom: 15px;
                        };
                        font: {
                            size: 14px;
                            weight: 600;
                        };
                    }
                    .list-group-labels {
                        .list-group-item {
                            text-decoration: none;
                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 18px;
                            };
                            margin-bottom: 12px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                            &::before {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                border: 2px solid $main-color;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }
                            &:hover {
                                color: $main-color;
                            }
                            &:nth-child(2) {
                                &::before {
                                    border-color: #f79921;
                                }
                            }
                            &:nth-child(3) {
                                &::before {
                                    border-color: #1ebe40;
                                }
                            }
                            &:nth-child(4) {
                                &::before {
                                    border-color: #40fefe;
                                }
                            }
                            &:nth-child(5) {
                                &::before {
                                    border-color: #707070;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .content-right {
        float: right;
        width: calc(100% - 290px);

        .todo-list-wrapper {
            .todo-list {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
                
                .todo-list-header {
                    padding: 15px 20px;
                    border-bottom: 1px solid #e8ebf1;

                    .header-left {
                        position: relative;
                        top: 3px;

                        .checkbox {
                            position: relative;
                            top: -2px;
                        }
                        .btn-icon {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 3px;
    
                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 47%;
                                transform: translateY(-47%);
                            }
                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }
                            &::after {
                                display: none;
                            }
                        }
                        .dropdown {
                            .dropdown-menu {
                                box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05);
                                background-color: $white-color;
                                border: none;
                                border-radius: 0;
                                margin-top: 35px;
                                display: block;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                transform: unset !important;
                
                                &.show {
                                    margin-top: 45px;
                                    opacity: 1;
                                    visibility: visible;
                                }
                                .dropdown-item {
                                    color: $black-color;
                                    padding: 5px 15px 5px 36px;
                                    position: relative;
                                    font: {
                                        size: 14px;
                                    };
                                    i {
                                        color: #686868;
                                        transition: $transition;
                                        position: absolute;
                                        left: 15px;
                                        top: 7px;
                                        font: {
                                            size: 16px;
                                        };
                                    }
                                    &.active, &:active {
                                        color: $black-color;
                                        background-color: transparent;
                                        background-color: #f8f9fa;
                                    }
                                    &:hover {
                                        i {
                                            transform: rotateY(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .header-right {
                        position: relative;

                        label {
                            position: absolute;
                            left: 15px;
                            color: #71738d;
                            margin-bottom: 0;
                            top: 14px;
                        }
                        .form-control {
                            height: 45px;
                            border-radius: 30px;
                            background-color: #eef5f9;
                            border: none;
                            color: $black-color;
                            padding: 2px 15px 0 39px;
                            transition: $transition;
                            box-shadow: unset;
                            font: {
                                size: 14px;
                                weight: 400;
                            };
                            &:focus {
                                box-shadow: unset;
                                background-color: #eef5f9;

                                &::placeholder {
                                    color: transparent;
                                }
                            }
                            &::placeholder {
                                color: #a8a8a8;
                                transition: $transition;
                            }
                        }
                    }
                }
                .list-wrapper {
                    height: calc(100vh - 277px);
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;

                    .todo-list-item {
                        position: relative;
                        transition: $transition;
                        border-bottom: 1px solid #e8ebf1;
                        padding: {
                            left: 60px;
                            right: 110px;
                            top: 15px;
                            bottom: 15px;
                        };
                        .checkbox {
                            position: absolute;
                            left: 25px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        .todo-item-title {
                            position: relative;
                            padding-left: 53px;

                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                position: absolute;
                                cursor: pointer;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            h3 {
                                margin-bottom: 2px;
                                font: {
                                    size: 16px;
                                    weight: 600;
                                };
                            }
                            p {
                                color: #919191;
                                font-size: $font-size;
                                margin-bottom: 0;
                            }
                        }
                        .todo-item-action {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 25px;

                            a {
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                line-height: 32px;
                                border-radius: 3px;
                                background-color: #c6e8cd;
                                color: #13bb37;
                                text-align: center;
                                margin-left: 3px;

                                &:hover {
                                    background-color: #13bb37;
                                    color: $white-color;
                                }
                                &.delete {
                                    background-color: #f9d0d9 !important;
                                    color: #e1000a !important;

                                    &:hover {
                                        background-color: #e1000a !important;
                                        color: $white-color !important;
                                    }
                                }
                            }
                        }
                        &:hover {
                            box-shadow: 0 0 10px 0 rgba(58,70,93,.25);
                        }
                        &:nth-child(2), &:nth-child(6), &:nth-child(10) {
                            .todo-item-action {
                                a {
                                    background-color: #d1def5;
                                    color: #4788ff;
                
                                    &:hover {
                                        background-color: #4788ff;
                                        color: $white-color;
                                    }
                                }
                            }
                        }
                        &:nth-child(3), &:nth-child(7), &:nth-child(11) {
                            .todo-item-action {
                                a {
                                    background-color: #f5d1c4;
                                    color: #fb4a06;
                
                                    &:hover {
                                        background-color: #fb4a06;
                                        color: $white-color;
                                    }
                                }
                            }
                        }
                        &:nth-child(4), &:nth-child(8), &:nth-child(12) {
                            .todo-item-action {
                                a {
                                    background-color: #d1def5;
                                    color: #4788ff;
                
                                    &:hover {
                                        background-color: #4788ff;
                                        color: $white-color;
                                    }
                                }
                            }
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
App Calendar CSS
=================================================*/
.calendar-container {
    overflow: hidden;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    border-radius: 5px;
    background: $white-color;

    .calendar {
        display: grid;
        width: 100%;
        overflow: auto;
        grid: {
            template-columns: repeat(7, minmax(120px, 1fr));
            template-rows: 50px;
            auto-rows: 120px;
        };
    }
    .calendar-header {
        position: relative;
        padding: 25px 20px;
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
        
        h3 {
            font: {
                size: 18px;
                weight: 700;
            };
        }
        a {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            background-color: $main-color;
            color: $white-color;
            transition: $transition;
            border-radius: 30px;
            text-decoration: none;
            padding: 10px 30px;
            font: {
                size: $font-size;
                weight: 700;
            };
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
    .day {
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
        border-right: 1px solid rgba(166, 168, 179, 0.12);
        text-align: right;
        padding: 14px 20px;
        letter-spacing: 1px;
        font-size: 12px;
        box-sizing: border-box;
        color: #898989;
        position: relative;
        pointer-events: none;
        z-index: 1;

        &:nth-of-type(7n + 7) {
            border-right: 0;
        }
        &:nth-of-type(n + 1):nth-of-type(-n + 7) {
            grid-row: 2;
        }
        &:nth-of-type(n + 8):nth-of-type(-n + 14) {
            grid-row: 3;
        }
        &:nth-of-type(n + 15):nth-of-type(-n + 21) {
            grid-row: 4;
        }
        &:nth-of-type(n + 22):nth-of-type(-n + 28) {
            grid-row: 5;
        }
        &:nth-of-type(n + 29):nth-of-type(-n + 35) {
            grid-row: 6;
        }
        &:nth-of-type(7n + 1) {
            grid-column: 1/1;
        }
        &:nth-of-type(7n + 2) {
            grid-column: 2/2;
        }
        &:nth-of-type(7n + 3) {
            grid-column: 3/3;
        }
        &:nth-of-type(7n + 4) {
            grid-column: 4/4;
        }
        &:nth-of-type(7n + 5) {
            grid-column: 5/5;
        }
        &:nth-of-type(7n + 6) {
            grid-column: 6/6;
        }
        &:nth-of-type(7n + 7) {
            grid-column: 7/7;
        }
    }
    .day-name {
        color: $black-color;
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
        line-height: 50px;
        text: {
            transform: uppercase;
            align: center;
        };
        font: {
            size: 12px;
            weight: 700;
        };
    }
    .day--disabled {
        background-color: $white-color;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
        cursor: not-allowed;
    }
    .task {
        border-left-width: 3px;
        padding: 8px 12px;
        margin: 10px;
        border-left-style: solid;
        font-size: 14px;
        position: relative;

        &.task--warning {
            border-left-color: #fdb44d;
            grid-column: 4 / span 3;
            grid-row: 3;
            background: #fef0db;
            align-self: center;
            color: #fc9b10;
            margin-top: -5px;
        }
        &.task--danger {
            border-left-color: #fa607e;
            grid-column: 2 / span 3;
            grid-row: 3;
            margin-top: 15px;
            background: rgba(253, 197, 208, 0.7);
            align-self: end;
            color: #f8254e;
        }
        &.task--info {
            border-left-color: #4786ff;
            grid-column: 6 / span 2;
            grid-row: 5;
            margin-top: 15px;
            background: rgba(218, 231, 255, 0.7);
            align-self: end;
            color: #0a5eff;
        }
        &.task--primary {
            background: #4786ff;
            border: 0;
            border-radius: 4px;
            grid-column: 3 / span 3;
            grid-row: 4;
            align-self: end;
            color: #fff;
            box-shadow: 0 10px 14px rgba(71, 134, 255, 0.4);
        }
        .task__detail {
            position: absolute;
            left: 0;
            top: calc(100% + 10px);
            background: $white-color;
            border: 1px solid rgba(166, 168, 179, 0.2);
            color: #000000;
            padding: 20px;
            border-radius: 4px;
            box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
            z-index: 2;

            &::after, &::before {
                bottom: 100%;
                left: 30%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }
            &::before {
                margin-left: -8px;
                border: {
                    bottom-color: rgba(166, 168, 179, 0.2);
                    width: 8px;
                };
            }
            &:after {
                margin-left: -6px;
                border: {
                    bottom-color: $white-color;
                    width: 6px;
                };
            }
            h4 {
                font-size: 15px;
                margin: 0;
                color: #51565d;
            }
            p {
                margin-top: 4px;
                margin-bottom: 0;
                color: rgba(81, 86, 93, 0.7);
                font: {
                    weight: 500;
                    size: 12px;
                };
            }
        }
    }
}

/*================================================
App Chat CSS
=================================================*/
.chat-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            background-color: $white-color;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
            padding: 25px;
            width: 300px;

            .chat-sidebar-header {
                padding-bottom: 25px;

                form {
                    label {
                        display: block;
                        margin-bottom: 0;
                        position: absolute;
                        left: 15px;
                        top: 11.3px;
                        font-size: 18px;
                        color: #a8a8a8;
                    }
                    .form-control {
                        height: 45px;
                        border-radius: 30px;
                        background-color: #eef5f9;
                        border: none;
                        color: $black-color;
                        padding: 0 15px 0 40px;
                        transition: $transition;
                        box-shadow: unset;
                        font: {
                            size: 14px;
                            weight: 400;
                        };
                        &:focus {
                            box-shadow: unset;
                            background-color: #eef5f9;
        
                            &::placeholder {
                                color: transparent !important;
                            }
                        }
                        &::placeholder {
                            color: #a8a8a8;
                            transition: $transition;
                        }
                    }
                }
            }
            .sidebar-content {
                height: calc(100vh - 321px);

                .chat-menu {
                    .list-group-user {
                        li {
                            cursor: pointer;
                            margin-bottom: 20px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                            .avatar {
                                position: relative;

                                .status-busy {
                                    background-color: #FF5B5C;
                                }
                                .status-online {
                                    background-color: #39DA8A;
                                }
                                .status-away {
                                    background-color: #FDAC41;
                                }
                                .status-offline {
                                    background-color: #475F7B;
                                }
                                [class*=status-] {
                                    border-radius: 50%;
                                    width: 10px;
                                    height: 10px;
                                    position: absolute;
                                    left: 1px;
                                    top: 1px;
                                }
                            }
                            h6 {
                                margin-bottom: 3px;
                                font: {
                                    size: 16px;
                                };
                            }
                            span {
                                color: #71738d;
                                font-size: 14.5px;
                            }
                        }
                    }
                    .list-group-label {
                        color: $black-color;
                        margin: {
                            top: 25px;
                            bottom: 20px;
                        };
                        padding-bottom: 5px;
                        position: relative;
                        border-bottom: 1px solid #eeeeee;
                        font: {
                            size: 18px;
                            weight: 700;
                        };
                        &::before {
                            width: 70px;
                            height: 1px;
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -1px;
                            background-color: $main-color;
                        }
                    }
                }
            }
        }
    }
    .content-right {
        float: right;
        width: calc(100% - 330px);

        .chat-list-wrapper {
            .chat-list {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
                
                .simplebar-scrollbar {
                    &::before {
                        background: #aaaaaa;
                    }
                }
                .chat-list-header {
                    padding: 15px 20px;

                    .header-left {
                        h6 {
                            white-space: nowrap;
                            font-size: 16px;
                        }
                        .avatar {
                            position: relative;

                            .status-busy {
                                background-color: #FF5B5C;
                            }
                            .status-online {
                                background-color: #39DA8A;
                            }
                            .status-away {
                                background-color: #FDAC41;
                            }
                            .status-offline {
                                background-color: #475F7B;
                            }
                            [class*=status-] {
                                border-radius: 50%;
                                width: 10px;
                                height: 10px;
                                position: absolute;
                                left: 1px;
                                top: 1px;
                            }
                        }
                    }
                    .header-right {
                        position: relative;
                        top: 1px;

                        ul {
                            li {
                                display: inline-block;
                                margin-left: 1px;

                                &:first-child {
                                    margin-left: 0;
                                }
                                .favorite {
                                    font-size: 20px;
                                    cursor: pointer;
                                    color: #C7CFD6;
                                    position: relative;
                                    top: -1px;
                                    
                                    &.active {
                                        color: #FDAC41;
    
                                        i {
                                            &::before {
                                                content: "\ed99";
                                            }
                                        }
                                    }
                                }
                                .dropdown {
                                    .dropdown-toggle {
                                        padding: 0;
                                        border: none;
                                        background-color: transparent;
                                        color: #727E8C;
                                        font-size: 22px;
                                        line-height: 15px;
                        
                                        &::after {
                                            display: none;
                                        }
                                    }
                                    .dropdown-menu {
                                        box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05);
                                        background-color: $white-color;
                                        border: none;
                                        border-radius: 0;
                                        margin-top: 35px;
                                        display: block;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                        transform: unset !important;
                                        left: auto !important;
                                        right: 0;
                        
                                        &.show {
                                            margin-top: 25px;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                        .dropdown-item {
                                            color: $black-color;
                                            padding: 5px 15px 5px 36px;
                                            position: relative;
                                            font: {
                                                size: 14px;
                                            };
                                            i {
                                                color: #686868;
                                                transition: $transition;
                                                position: absolute;
                                                left: 15px;
                                                top: 7px;
                                                font: {
                                                    size: 16px;
                                                };
                                            }
                                            &.active, &:active {
                                                color: $black-color;
                                                background-color: transparent;
                                                background-color: #f8f9fa;
                                            }
                                            &:hover {
                                                i {
                                                    transform: rotateY(-180deg);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .chat-container {
                    height: calc(100vh - 338px);
                    background-color: #E3E6F1;
                    padding: 25px 20px;
                    background: {
                        image: url(assets/img/chat-bg.png);
                        position: center center;
                        size: contain;
                        repeat: repeat;
                    };
                    .chat-content {
                        text-align: center;

                        .chat {
                            .chat-avatar {
                                float: right;
                            }
                            .chat-body {
                                overflow: hidden;
    
                                .chat-message {
                                    position: relative;
                                    float: right;
                                    max-width: 420px;
                                    text-align: left;
                                    padding: 12px 16px;
                                    margin: 10px 21.28px 21.28px 0;
                                    clear: both;
                                    word-break: break-word;
                                    color: $white-color;
                                    background-color: #3578e5;
                                    border-radius: 5px;
                                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);
    
                                    p {
                                        color: $white-color;
                                        margin-bottom: 0;

                                        a {
                                            color: $white-color !important;
                                        }
                                    }
                                    .time {
                                        position: absolute;
                                        bottom: -25px;
                                        right: 0;
                                        color: #6b7886;
                                        font-size: .8rem;
                                        white-space: nowrap;
                                    }
                                    &:first-child {
                                        margin-top: 0 !important;
                                    }
                                }
                            }
                            &.chat-left {
                                .chat-avatar {
                                    float: left;
                                }
                                .chat-body {
                                    .chat-message {
                                        text-align: left;
                                        float: left;
                                        margin: 10px 0 21.28px 21.28px;
                                        color: #727E8C;
                                        background-color: $white-color;
                                        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);

                                        p {
                                            color: #727E8C;

                                            a {
                                                color: #727E8C !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .badge-light {
                            padding: 9px 20px 7px;
                            text-transform: uppercase;
                        }
                    }
                }
                .chat-list-footer {
                    background-color: #fafafa;
                    padding: 15px 20px;

                    .emoji-btn {
                        padding: 0;
                        border: none;
                        background-color: #d4ebf7;
                        border-radius: 50%;
                        width: 35px;
                        font-size: 19px;
                        line-height: 40px;
                        height: 35px;
                        color: $main-color;
                        transition: $transition;

                        &:hover, &:focus {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                    .file-attachment-btn {
                        padding: 0;
                        border: none;
                        background-color: #d4ebf7;
                        border-radius: 50%;
                        width: 35px;
                        font-size: 19px;
                        line-height: 40px;
                        height: 35px;
                        color: $main-color;
                        transition: $transition;

                        &:hover, &:focus {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                    form {
                        position: relative;
                        padding-right: 128px;

                        .form-control {
                            background-color: $white-color;
                            height: 45px;
                            border-radius: 30px;
                            transition: $transition;
                            padding-left: 15px;
                            font-size: 14.5px;
                            border: 1px solid #DFE3E7;
                            color: $black-color;

                            &:focus {
                                border-color: $main-color;
                                box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .1);
                            }
                        }
                        .send-btn {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            border: none;
                            background-color: $main-color;
                            color: $white-color;
                            transition: $transition;
                            border-radius: 30px;
                            height: 45px;
                            padding: 0 30px;
                            box-shadow: 0 4px 12px 0 rgba(8, 141, 211, .2);
                            font: {
                                size: $font-size;
                                weight: 700;
                            };
                            &:hover {
                                box-shadow: 0 4px 12px 0 rgba(8, 141, 211, .6);
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Profile CSS
=================================================*/
.my-profile-box {
    background-color: $white-color;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    border-radius: 5px;
    padding: 30px 30px 15px;

    h3 {
        margin-bottom: 25px;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 5px;
        position: relative;
        font: {
            size: 20px;
            weight: 700;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 50px;
            height: 1px;
            background-color: $main-color;
        }
    }
    form {
        .form-group {
            margin-bottom: 20px;
            position: relative;

            label {
                display: block;
                margin-bottom: 8px;
                color: $black-color;
            }
            .form-control {
                height: 46px;
                background: #f9f9f9;
                transition: $transition;
                border-radius: 5px;
                border: 1px solid #e5e7f2;
                color: $black-color;
                font: {
                    weight: 400;
                    size: $font-size;
                };
                padding: {
                    top: 0;
                    bottom: 0;
                    right: 15px;
                    left: 15px;
                };
                &::placeholder {
                    transition: $transition;
                    color: #7d93b2;
                }
                &:focus {
                    border-color: $main-color;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            textarea.form-control {
                padding-top: 15px;
                height: auto;
            }
        }
        button {
            display: block;
            width: 100%;
            background-color: $main-color;
            color: $white-color;
            padding: 12.5px 30px;
            box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
            border-radius: 5px;
            transition: $transition;
            border: none;
            font: {
                size: $font-size;
                weight: 700;
            };
            &:hover {
                background-color: #3b99ca;
                color: $white-color;
            }
        }
        h3 {
            margin-top: 15px;
        }
    }
    .profile-box {
        img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
        }
        .btn-box {
            margin-left: 20px;

            .delete-btn {
                display: inline-block;
                background-color: #fbe7e7;
                color: $black-color;
                padding: 10px 25px 9px;
                border-radius: 5px;
                text-decoration: none;
                transition: $transition;
                font: {
                    size: $font-size;
                    weight: 600;
                };
                &:hover {
                    background-color: red;
                    color: $white-color;
                }
            }
            .file-upload {
                display: inline-block;
                margin-right: 15px;

                .inputfile {
                    width: 0.1px;
                    height: 0.1px;
                    opacity: 0;
                    overflow: hidden;
                    position: absolute;
                    z-index: -1;
                }
                .inputfile + label {
                    color: $white-color;
                    background-color: $main-color;
                    display: inline-block;
                    margin-bottom: 0;
                    transition: $transition;
                    padding: 10px 25px 9px;
                    border-radius: 5px;
                    font: {
                        size: $font-size;
                        weight: 600;
                    };
                }
                .inputfile:focus + label, .inputfile + label:hover {
                    background-color: #3b99ca;
                    color: $white-color;
                }
                .inputfile + label {
                    cursor: pointer;
                }
            }
        }
    }
}

/*================================================
Profile CSS
=================================================*/

.listing-detail-area-box{
    form {
        .listing-detail-box {
            background-color: $white-color;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
            border-radius: 5px;
            padding: 30px 30px 15px;
        
            h3 {
                margin-bottom: 30px;
                border-bottom: 1px solid #eeeeee;
                padding-bottom: 6px;
                position: relative;
                font: {
                    size: 20px;
                    weight: 700;
                };
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    width: 50px;
                    height: 1px;
                    background-color: $main-color;
                }
            }
            .form-group {
                margin-bottom: 25px;
                position: relative;
    
                label {
                    display: block;
                    margin-bottom: 10px;
                    color: $black-color;
                    position: relative;
                    padding-left: 0px;
    
                    i {
                        color: $main-color;
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-size: 19px;
                    }
                }
                .form-control {
                    height: 48px;
                    background: #f9f9f9;
                    transition: $transition;
                    border-radius: 5px;
                    border: 1px solid #e5e7f2;
                    color: $black-color;
                    font: {
                        weight: 400;
                        size: $font-size;
                    };
                    padding: {
                        top: 0;
                        bottom: 0;
                        right: 15px;
                        left: 15px;
                    };
                    &::placeholder {
                        transition: $transition;
                        color: #7d93b2;
                    }
                    &:focus {
                        border-color: $main-color;
    
                        &::placeholder {
                            color: transparent;
                        }
                    }
                }
                textarea.form-control {
                    padding-top: 15px;
                    height: auto;
                }
                .nice-select {
                    display: block;
                    height: 48px;
                    line-height: 48px;
                    background: #f9f9f9;
                    transition: $transition;
                    border-radius: 5px;
                    border: 1px solid #e5e7f2;
                    color: $black-color;
                    font: {
                        weight: 400;
                        size: $font-size;
                    };
                    .list {
                        background-color: $white-color;
                        border-radius: 5px;
                        box-shadow: 0 10px 30px rgba(0,0,0,.2);
                        list-style-type: none;
                        border: none;
                        width: 100%;
                        margin: {
                            bottom: 0;
                            top: 0;
                        };
                        padding: {
                            left: 0;
                            top: 10px;
                            bottom: 10px;
                        };
                        .option {
                            line-height: 38px;
                            min-height: 38px;
                            color: $black-color;
                            position: relative;
                            transition: $transition;
                            padding: {
                                left: 20px;
                                right: 20px;
                            };
                            font: {
                                size: $font-size;
                                weight: 600;
                            };
                            &:hover {
                                background-color: $main-color !important;
                                color: $white-color;
        
                                &::before {
                                    color: $white-color;
                                }
                            }
                            &.focus, &.selected.focus {
                                background-color: transparent !important;
                                color: $black-color;
                            }
                            &.selected {
                                &::before {
                                    opacity: 1;
                                    visibility: visible;
                                }
                                &:hover {
                                    background-color: $main-color !important;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                    &:after {
                        border-color: $black-color;
                        height: 8px;
                        width: 8px;
                        margin-top: -5px;
                        right: 15px;
                    }
                    &:focus {
                        border-color: $main-color;
                        background-color: transparent;
                    }
                }
                .sidebar-widgets {
                    .box {
                        background: #f6f6f6;
                        border: 1px solid #eeeeee;
                        border-radius: 5px;
                        padding: 20px;
                        position: relative;
                        margin-bottom: 15px;
    
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .title {
                            display: block;
                            color: $black-color;
                            position: relative;
                            font-weight: 600;
                        }
                        .switch {
                            padding-left: 0;
                            margin-bottom: 0;
                            display: inline-block;
                            position: absolute;
                            right: 20px;
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
    
                            input {
                                display: none;
                            }
                            input + span {
                                width: 48px;
                                height: 28px;
                                border-radius: 14px;
                                -webkit-transition: all .3s ease;
                                transition: all .3s ease;
                                display: block;
                                position: relative;
                                background: $main-color;
                                box-shadow: 0 8px 16px -1px rgba(8, 141, 211, 0.2);
                            }
                            input + span:before, input + span:after {
                                content: '';
                                display: block;
                                position: absolute;
                                -webkit-transition: all .3s ease;
                                transition: all .3s ease;
                            }
                            input + span:before {
                                top: 5px;
                                left: 5px;
                                width: 18px;
                                height: 18px;
                                border-radius: 9px;
                                border: 5px solid $white-color;
                            }
                            input + span:after {
                                top: 5px;
                                left: 32px;
                                width: 6px;
                                height: 18px;
                                border-radius: 40%;
                                transform-origin: 50% 50%;
                                background: $white-color;
                                opacity: 0;
                            }
                            input + span:active {
                                transform: scale(0.92);
                            }
                            input:checked + span {
                                background: #48EA8B;
                                box-shadow: 0 8px 16px -1px rgba(72, 234, 139, 0.2);
                            }
                            input:checked + span:before {
                                width: 0px;
                                border-radius: 3px;
                                margin-left: 27px;
                                border-width: 3px;
                                background: $white-color;
                            }
                            input:checked + span:after {
                                animation: blobChecked .35s linear forwards .2s;
                            }
                            input:not(:checked) + span:before {
                                animation: blob .85s linear forwards .2s;
                            }
                        }
                    }
                }
                .add-list-media-header {
                    margin-bottom: 20px;
    
                    .radio {
                        margin-bottom: 0;
                        padding-left: 0;
                        cursor: pointer;
                        display: inline-block;
    
                        input {
                            display: none;
                        }
                        input + span {
                            line-height: 20px;
                            height: 20px;
                            padding-left: 20px;
                            display: block;
                            position: relative;
                        }
                        input + span:not(:empty) {
                            padding-left: 28px;
                        }
                        input + span:before, input + span:after {
                            content: '';
                            width: 20px;
                            height: 20px;
                            display: block;
                            border-radius: 50%;
                            left: 0;
                            top: -1px;
                            position: absolute;
                        }
                        input + span:before {
                            background: #eeeeee;
                            transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2), -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
                        }
                        input + span:after {
                            background: #eeeeee;
                            transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4), -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
                        }
                        input:checked + span:before {
                            background: $main-color;
                        }
                        input:checked + span:after {
                            transform: scale(0.4);
                            transition: transform .3s ease, -webkit-transform .3s ease;
                        }
                    }
                }
                .add-list-media-wrap {
                    position: relative;
                    border: 1px solid #eeeeee;
                    border-radius: 5px;
                    background: #F7F9FB;
                    transition: all 0.3s linear;
                    text-align: center;
                    cursor: pointer;
    
                    .text {
                        span {
                            display: block;
                            font: {
                                weight: 600;
                            };
                            padding: {
                                top: 58px;
                                bottom: 58px;
                            };
                            i {
                                line-height: 1;
                                font-size: 35px;
                                color: $main-color;
                                margin-bottom: 10px;
                                display: block;
                            }
                        }
                    }
                    input {
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 1;
                        cursor: pointer;
                        opacity: 0;
                    }
                }
                .facilities-list {
                    padding-left: 0;
                    margin-bottom: -15px;
                    list-style-type: none;
                    overflow: hidden;
    
                    li {
                        float: left;
                        margin-right: 20px;
                        position: relative;
                        margin-bottom: 10px;
    
                        .checkbox {
                            margin-bottom: 0;
                            padding-left: 0;
                            cursor: pointer;
                            display: inline-block;
        
                            input {
                                display: none;
                            }
                            input + span {
                                line-height: 20px;
                                height: 20px;
                                padding-left: 20px;
                                display: block;
                                position: relative;
                                font-weight: 600;
                            }
                            input + span:not(:empty) {
                                padding-left: 28px;
                            }
                            input + span:before, input + span:after {
                                content: '';
                                width: 20px;
                                height: 20px;
                                display: block;
                                border-radius: 50%;
                                left: 0;
                                top: -1px;
                                position: absolute;
                            }
                            input + span:before {
                                background: #eeeeee;
                                transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2), -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
                            }
                            input + span:after {
                                background: #eeeeee;
                                transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4), -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
                            }
                            input:checked + span:before {
                                background: $main-color;
                            }
                            input:checked + span:after {
                                transform: scale(0.4);
                                transition: transform .3s ease, -webkit-transform .3s ease;
                            }
                        }
                    }
                }
                .add-list-content-widgets {
                    margin-bottom: 20px;
    
                    .checkbox {
                        margin-bottom: 0;
                        padding-left: 0;
                        cursor: pointer;
                        display: inline-block;
    
                        input {
                            display: none;
                        }
                        input + span {
                            line-height: 20px;
                            height: 20px;
                            padding-left: 20px;
                            display: block;
                            position: relative;
                        }
                        input + span:not(:empty) {
                            padding-left: 28px;
                        }
                        input + span:before, input + span:after {
                            content: '';
                            width: 20px;
                            height: 20px;
                            display: block;
                            border-radius: 50%;
                            left: 0;
                            top: -1px;
                            position: absolute;
                        }
                        input + span:before {
                            background: #eeeeee;
                            transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2), -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
                        }
                        input + span:after {
                            background: #eeeeee;
                            transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4), -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
                        }
                        input:checked + span:before {
                            background: $main-color;
                        }
                        input:checked + span:after {
                            transform: scale(0.4);
                            transition: transform .3s ease, -webkit-transform .3s ease;
                        }
                    }
                }
            }
            .button {
                display: inline-block;
                background-color: $main-color;
                color: $white-color;
                padding: 12.5px 30px;
                box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
                border-radius: 5px;
                transition: $transition;
                border: none;
                margin-bottom: 20px;
                font: {
                    size: $font-size;
                    weight: 700;
                };
                &:hover {
                    background-color: #3b99ca;
                    color: $white-color;
                }
            }
            .map {
                iframe {
                    width: 100%;
                    border: none;
                    height: 400px;
                }
            }
        }        
    }
}


.add-listing-box {
    background-color: $white-color;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    border-radius: 5px;
    padding: 30px 30px 15px;

    h3 {
        margin-bottom: 30px;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 6px;
        position: relative;
        font: {
            size: 20px;
            weight: 700;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 50px;
            height: 1px;
            background-color: $main-color;
        }
    }
    form {
        .form-group {
            margin-bottom: 25px;
            position: relative;

            label {
                display: block;
                margin-bottom: 10px;
                color: $black-color;
                position: relative;
                padding-left: 25px;

                i {
                    color: $main-color;
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 19px;
                }
            }
            .form-control {
                height: 48px;
                background: #f9f9f9;
                transition: $transition;
                border-radius: 5px;
                border: 1px solid #e5e7f2;
                color: #7d93b2;
                font: {
                    weight: 400;
                    size: $font-size;
                };
                padding: {
                    top: 0;
                    bottom: 0;
                    right: 15px;
                    left: 15px;
                };
                &::placeholder {
                    transition: $transition;
                    color: #7d93b2;
                }
                &:focus {
                    border-color: $main-color;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            textarea.form-control {
                padding-top: 15px;
                height: auto;
            }
            .nice-select {
                display: block;
                height: 48px;
                line-height: 48px;
                background: #f9f9f9;
                transition: $transition;
                border-radius: 5px;
                border: 1px solid #e5e7f2;
                color: #7d93b2;
                font: {
                    weight: 400;
                    size: $font-size;
                };
                .list {
                    background-color: $white-color;
                    border-radius: 5px;
                    box-shadow: 0 10px 30px rgba(0,0,0,.2);
                    list-style-type: none;
                    border: none;
                    width: 100%;
                    margin: {
                        bottom: 0;
                        top: 0;
                    };
                    padding: {
                        left: 0;
                        top: 10px;
                        bottom: 10px;
                    };
                    .option {
                        line-height: 38px;
                        min-height: 38px;
                        color: $black-color;
                        position: relative;
                        transition: $transition;
                        padding: {
                            left: 20px;
                            right: 20px;
                        };
                        font: {
                            size: $font-size;
                            weight: 600;
                        };
                        &:hover {
                            background-color: $main-color !important;
                            color: $white-color;
    
                            &::before {
                                color: $white-color;
                            }
                        }
                        &.focus, &.selected.focus {
                            background-color: transparent !important;
                            color: $black-color;
                        }
                        &.selected {
                            &::before {
                                opacity: 1;
                                visibility: visible;
                            }
                            &:hover {
                                background-color: $main-color !important;
                                color: $white-color;
                            }
                        }
                    }
                }
                &:after {
                    border-color: $black-color;
                    height: 8px;
                    width: 8px;
                    margin-top: -5px;
                    right: 15px;
                }
                &:focus {
                    border-color: $main-color;
                    background-color: transparent;
                }
            }
            .sidebar-widgets {
                .box {
                    background: #f6f6f6;
                    border: 1px solid #eeeeee;
                    border-radius: 5px;
                    padding: 20px;
                    position: relative;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    .title {
                        display: block;
                        color: $black-color;
                        position: relative;
                        font-weight: 600;
                    }
                    .switch {
                        padding-left: 0;
                        margin-bottom: 0;
                        display: inline-block;
                        position: absolute;
                        right: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;

                        input {
                            display: none;
                        }
                        input + span {
                            width: 48px;
                            height: 28px;
                            border-radius: 14px;
                            -webkit-transition: all .3s ease;
                            transition: all .3s ease;
                            display: block;
                            position: relative;
                            background: $main-color;
                            box-shadow: 0 8px 16px -1px rgba(8, 141, 211, 0.2);
                        }
                        input + span:before, input + span:after {
                            content: '';
                            display: block;
                            position: absolute;
                            -webkit-transition: all .3s ease;
                            transition: all .3s ease;
                        }
                        input + span:before {
                            top: 5px;
                            left: 5px;
                            width: 18px;
                            height: 18px;
                            border-radius: 9px;
                            border: 5px solid $white-color;
                        }
                        input + span:after {
                            top: 5px;
                            left: 32px;
                            width: 6px;
                            height: 18px;
                            border-radius: 40%;
                            transform-origin: 50% 50%;
                            background: $white-color;
                            opacity: 0;
                        }
                        input + span:active {
                            transform: scale(0.92);
                        }
                        input:checked + span {
                            background: #48EA8B;
                            box-shadow: 0 8px 16px -1px rgba(72, 234, 139, 0.2);
                        }
                        input:checked + span:before {
                            width: 0px;
                            border-radius: 3px;
                            margin-left: 27px;
                            border-width: 3px;
                            background: $white-color;
                        }
                        input:checked + span:after {
                            animation: blobChecked .35s linear forwards .2s;
                        }
                        input:not(:checked) + span:before {
                            animation: blob .85s linear forwards .2s;
                        }
                    }
                }
            }
            .add-list-media-header {
                margin-bottom: 20px;

                .radio {
                    margin-bottom: 0;
                    padding-left: 0;
                    cursor: pointer;
                    display: inline-block;

                    input {
                        display: none;
                    }
                    input + span {
                        line-height: 20px;
                        height: 20px;
                        padding-left: 20px;
                        display: block;
                        position: relative;
                    }
                    input + span:not(:empty) {
                        padding-left: 28px;
                    }
                    input + span:before, input + span:after {
                        content: '';
                        width: 20px;
                        height: 20px;
                        display: block;
                        border-radius: 50%;
                        left: 0;
                        top: -1px;
                        position: absolute;
                    }
                    input + span:before {
                        background: #eeeeee;
                        transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2), -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
                    }
                    input + span:after {
                        background: #eeeeee;
                        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4), -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
                    }
                    input:checked + span:before {
                        background: $main-color;
                    }
                    input:checked + span:after {
                        transform: scale(0.4);
                        transition: transform .3s ease, -webkit-transform .3s ease;
                    }
                }
            }
            .add-list-media-wrap {
                position: relative;
                border: 1px solid #eeeeee;
                border-radius: 5px;
                background: #F7F9FB;
                transition: all 0.3s linear;
                text-align: center;
                cursor: pointer;

                .text {
                    span {
                        display: block;
                        font: {
                            weight: 600;
                        };
                        padding: {
                            top: 58px;
                            bottom: 58px;
                        };
                        i {
                            line-height: 1;
                            font-size: 35px;
                            color: $main-color;
                            margin-bottom: 10px;
                            display: block;
                        }
                    }
                }
                input {
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                    cursor: pointer;
                    opacity: 0;
                }
            }
            .facilities-list {
                padding-left: 0;
                margin-bottom: -15px;
                list-style-type: none;
                overflow: hidden;

                li {
                    float: left;
                    margin-right: 20px;
                    position: relative;
                    margin-bottom: 10px;

                    .checkbox {
                        margin-bottom: 0;
                        padding-left: 0;
                        cursor: pointer;
                        display: inline-block;
    
                        input {
                            display: none;
                        }
                        input + span {
                            line-height: 20px;
                            height: 20px;
                            padding-left: 20px;
                            display: block;
                            position: relative;
                            font-weight: 600;
                        }
                        input + span:not(:empty) {
                            padding-left: 28px;
                        }
                        input + span:before, input + span:after {
                            content: '';
                            width: 20px;
                            height: 20px;
                            display: block;
                            border-radius: 50%;
                            left: 0;
                            top: -1px;
                            position: absolute;
                        }
                        input + span:before {
                            background: #eeeeee;
                            transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2), -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
                        }
                        input + span:after {
                            background: #eeeeee;
                            transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4), -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
                        }
                        input:checked + span:before {
                            background: $main-color;
                        }
                        input:checked + span:after {
                            transform: scale(0.4);
                            transition: transform .3s ease, -webkit-transform .3s ease;
                        }
                    }
                }
            }
            .add-list-content-widgets {
                margin-bottom: 20px;

                .checkbox {
                    margin-bottom: 0;
                    padding-left: 0;
                    cursor: pointer;
                    display: inline-block;

                    input {
                        display: none;
                    }
                    input + span {
                        line-height: 20px;
                        height: 20px;
                        padding-left: 20px;
                        display: block;
                        position: relative;
                    }
                    input + span:not(:empty) {
                        padding-left: 28px;
                    }
                    input + span:before, input + span:after {
                        content: '';
                        width: 20px;
                        height: 20px;
                        display: block;
                        border-radius: 50%;
                        left: 0;
                        top: -1px;
                        position: absolute;
                    }
                    input + span:before {
                        background: #eeeeee;
                        transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2), -webkit-transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
                    }
                    input + span:after {
                        background: #eeeeee;
                        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4), -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
                    }
                    input:checked + span:before {
                        background: $main-color;
                    }
                    input:checked + span:after {
                        transform: scale(0.4);
                        transition: transform .3s ease, -webkit-transform .3s ease;
                    }
                }
            }
        }
        button {
            display: inline-block;
            background-color: $main-color;
            color: $white-color;
            padding: 12.5px 30px;
            box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
            border-radius: 5px;
            transition: $transition;
            border: none;
            margin-bottom: 20px;
            font: {
                size: $font-size;
                weight: 700;
            };
            &:hover {
                background-color: #3b99ca;
                color: $white-color;
            }
        }
        .map {
            iframe {
                width: 100%;
                border: none;
                height: 400px;
            }
        }
    }
}
@keyframes blob {
    0%, 100% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.12, 0.94);
    }
    60% {
        transform: scale(0.96, 1.06);
    }
}
@keyframes blobChecked {
    0% {
        opacity: 1;
        transform: scaleX(1);
    }
    30% {
        transform: scaleX(1.44);
    }
    70% {
        transform: scaleX(1.18);
    }
    50%, 99% {
        transform: scaleX(1);
        opacity: 1;
    }
    100% {
        transform: scaleX(1);
        opacity: 0;
    }
}

/*================================================
Listing CSS
=================================================*/
.single-listing-item {
    background-color: $white-color;
    box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    border-radius: 5px 5px 0 0;
    transition: $transition;
    margin-bottom: 30px;
    position: relative;

    .listing-image {
        border-radius: 5px 5px 0 0;
        position: relative;

        a {
            display: block;
            text-decoration: none;

            img {
                border-radius: 5px 5px 0 0;
            }
        }
        .listing-tag {
            position: absolute;
            right: 20px;
            bottom: 0;
            background-color: $white-color;
            border-radius: 5px 5px 0 0;
            transition: $transition;
            padding: {
                left: 15px;
                right: 15px;
                top: 5px;
                bottom: 5px;
            };
            font: {
                size: 14px;
                weight: 600;
            };
            div {
                display: inline-block;
                color: $main-color;
                text-decoration: none;
            }
        }
        .listing-rating {
            position: absolute;
            left: 20px;
            color: $white-color;
            bottom: 5px;

            .review-stars-rated {
                color: #f2b827;
                display: inline-block;

                i {
                    color: #f2b827;
                }
            }
            .rating-total {
                display: inline-block;
                position: relative;
                color: $white-color;
                top: -1px;
                margin-left: 2px;
                font: {
                    size: 13px;
                    weight: 600;
                };
            }
        }
    }
    .listing-content {
        padding: 20px;

        .listing-author {
            margin-bottom: 13px;

            img {
                width: 37px;
                height: 37px;
            }
            span {
                margin-left: 3px;
                display: inline-block;
                font: {
                    size: $font-size;
                    weight: 600;
                };
            }
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 22px;
                weight: 700;
            };
            a {
                display: inline-block;
                color: $black-color;
                text-decoration: none;

                &:hover {
                    color: $main-color;
                }
            }
        }
        .location {
            display: block;
            color: #777777;
            margin-top: 12px;
            position: relative;
            padding-left: 18px;
            font: {
                size: 13.5px;
                weight: 600;
            };
            i {
                position: absolute;
                left: 0;
                color: $main-color;
                top: 3.7px;
            }
        }
        .phone-number {
            display: block;
            color: #777777;
            margin-top: 12px;
            position: relative;
            padding-left: 18px;
            font: {
                size: 13.5px;
                weight: 600;
            };
            i {
                position: absolute;
                left: 0;
                color: $main-color;
                top: 3.7px;
            }
            a {
                display: inline-block;
                color: #777777;

                &:hover {
                    color: $main-color;
                }
            }
        }
        .email {
            display: block;
            color: #777777;
            margin-top: 12px;
            position: relative;
            padding-left: 18px;
            font: {
                size: 13.5px;
                weight: 600;
            };
            i {
                position: absolute;
                left: 0;
                color: $main-color;
                top: 3.7px;
            }
            a {
                display: inline-block;
                color: #777777;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    .listing-box-footer {
        border-top: 1px solid #e2f4ff;
        padding: 20px;

        .listing-option-list {
            a {
                display: inline-block;
                width: 32px;
                height: 32px;
                line-height: 35px;
                text-align: center;
                margin-right: 5px;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                background-color: $white-color;
                color: $black-color;
                border-radius: 2px;

                &:last-child {
                    margin-right: 0;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
        .price {
            span {
                display: inline-block;
                cursor: pointer;
                color: $main-color;
                font: {
                    weight: 600;
                    size: $font-size;
                };
            }
        }
    }
    .listing-badge {
        position: absolute;
        right: 20px;
        top: 20px;
        background-color: #5ECFB1;
        color: $white-color;
        text-align: center;
        z-index: 2;
        padding: 3px 12px;
        text-transform: uppercase;
        font: {
            weight: 600;
            size: 13.5px;
        };
        &.closed {
            background-color: red;
            color: $white-color;
        }
    }
    .listing-discount {
        position: absolute;
        top: -6.1px;
        left: 20px;
        display: inline-block;

        span {
            position: relative;
            display: block;
            text-align: center;
            line-height: 1;
            padding: 12px 8px 10px;
            border-top-right-radius: 8px;
            width: 90px;
            background-color: $main-color;
            color: $white-color;
            text-transform: uppercase;
            font: {
                weight: 600;
                size: 14px;
            };
            &::before {
                height: 6px;
                width: 6px;
                left: -6px;
                position: absolute;
                content: "";
                top: 0;
                background-color: $main-color;
            }
            &::after {
                position: absolute;
                content: "";
                height: 6px;
                width: 8px;
                left: -8px;
                top: 0;
                border-radius: 8px 8px 0 0;
                background-color: $main-color;
            }
        }
    }
    &:hover, &:focus {
        transform: translateY(-10px);

        .listing-image {
            .listing-tag {
                background-color: $main-color;

                div {
                    color: $white-color;
                }
            }
        }
    }
    .listing-edit-delete-option {
        padding: 20px;
        border-top: 1px solid #eeeeee;
    }
}
.listing-area {
    .nav {
        margin-bottom: 30px;
        list-style-type: none;
        border: none;
        border-bottom: 1px solid #eeeeee;

        .nav-item {
            margin-bottom: 0;
            
            .nav-link {
                position: relative;
                color: #777777;
                border: none;
                background-color: transparent;
                padding: {
                    top: 0;
                    bottom: 5px;
                    left: 5px;
                    right: 5px;
                };
                font: {
                    size: 18px;
                    weight: 600;
                };
                margin: {
                    left: 10px;
                    right: 10px;
                };
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    width: 100%;
                    height: 2px;
                    background-color: $main-color;
                    transition: $transition;
                    transform: scaleX(0);
                }
                &:hover, &.active {
                    color: $main-color;

                    &::before {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
    margin-top: 15px;

    .page-numbers {
        width: 33px;
        height: 33px;
        background-color: #f3f0f0;
        color: #777777;
        text-align: center;
        display: inline-block;
        border-radius: 3px;
        line-height: 34px;
        position: relative;
        text-decoration: none;
        margin: {
            left: 3px;
            right: 3px;
        };
        font: {
            size: 17px;
            weight: 800;
        };
        &:hover, &.current {
            color: $white-color;
            background-color: $main-color;
        }
        i {
            position: relative;
            top: 1.5px;
        }
    }
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
    padding-bottom: 25px;
    border-top: 1px solid #eeeeee;
    padding-top: 25px;

    p {
        font-size: $font-size;
        margin-bottom: 0;
        color: #777777;

        i {
            position: relative;
            top: 2px;
        }
        a {
            display: inline-block;
            color: #777777;

            &:hover {
                color: $main-color;
                text-decoration: none;
            }
        }
    }
}

/*================================================
Login Area CSS
=================================================*/
.login-image {
    height: 100%;
    width: 100%;
    background: {
        image: url(assets/img/login-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
}
.login-content {
    height: 100vh;
    
    .login-form {
        text-align: center;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
        };
        .logo {
            margin-bottom: 25px;            
            a {
                cursor: pointer;
                display: inline-block;
            }
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 40px;
                weight: 700;
            };
        }
        p {
            margin: {
                top: 10px;
                bottom: 0;
            };
            a {
                display: inline-block;
            }
        }
        form {
            margin-top: 35px;

            .form-control {
                background-color: $white-color;
                color: $black-color;
                border: none;
                transition: $transition;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
                height: 55px;
                font: {
                    size: $font-size;
                };
                &::placeholder {
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                transition: $transition;
                display: block;
                width: 100%;
                padding: 14px 25px 13px;
                text-transform: uppercase;
                position: relative;
                background-color: $main-color;
                color: $white-color;
                box-shadow: 0px 5px 28.5px 1.5px rgba(8, 141, 211, .15);
                border: {
                    width: 2px;
                    style: solid;
                    color: $main-color;
                    radius: 5px;
                };
                font: {
                    size: 14.5px;
                    weight: 700;
                };
                &:hover, &:focus {
                    background-color: $main-color2;
                    color: $white-color;
                    border-color: $main-color2;
                }
            }
            .forgot-password {
                text-align: end;
                margin-top: 20px;

                a {
                    display: inline-block;
                    color: $main-color;
                    text-decoration: underline;
                    font-weight: 600;

                    &:hover {
                        color: $main-color2;
                    }
                }
            }
            .connect-with-social {
                margin-top: 20px;

                button {
                    display: block;
                    width: 100%;
                    position: relative;
                    border: 1px solid $main-color;
                    background-color: transparent;
                    transition: $transition;
                    padding: 12px 30px;
                    border-radius: 2px;
                    color: $main-color;
                    box-shadow: unset;
                    font: {
                        weight: 600;
                        size: 14.5px;
                    }
                    i {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 15px;
                        font-size: 20px;
                    }
                    &.facebook {
                        border-color: #3b5998;
                        color: #3b5998;
                        
                        &:hover {
                            background-color: #3b5998;
                            color: $white-color;
                            border-color: #3b5998;
                        }
                    }
                    &.twitter {
                        margin-top: 15px;
                        border-color: #1da1f2;
                        color: #1da1f2;
                        
                        &:hover {
                            background-color: #1da1f2;
                            color: $white-color;
                            border-color: #1da1f2;
                        }
                    }
                    &.google {
                        margin-top: 15px;
                        border-color: #DB4437;
                        color: #DB4437;
                        
                        &:hover {
                            background-color: #DB4437;
                            color: $white-color;
                            border-color: #DB4437;
                        }
                    }
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    padding: {
        top: 250px;
        bottom: 130px;
    };
    background: {
        color: $black-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $black-color;
        opacity: .80;
    }
}
.page-title-content {
    text-align: center;

    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 40px;
            weight: 700;
        };
    }
}

.hover{
    :hover {
        cursor:pointer;
    }
}


/*================================================
Listing Details Area CSS
=================================================*/
.listing-details-header {
    margin-bottom: 30px;

    .listing-title {
        h2 {
            margin-bottom: 10px;
            font: {
                size: 30px;
                weight: 700;
            };
        }
        &.listing-badge {
            width: 100px;
            height: 38px;
            line-height: 39px;
            padding-left: 14px;
            position: absolute;
            color: $white-color;
            top: 33px;
            right: 0;
            text-transform: uppercase;
            background-color: $main-color;
            font: {
                size: 12.5px;
                weight: 500;
                family: $font-family2;
            };
            &::before {
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                top: -8.5px;
                right: 0;
                border-bottom: 9px solid $main-color;
                border-right: 9px solid transparent;
            }
        }
    }
    .listing-meta {
        margin-top: 25px;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-right: 20px;
                position: relative;
                display: inline-block;
                border-right: 1px solid #eeeeee;
                font: {
                    weight: 500;
                    size: 16px;
                };
                padding: {
                    right: 20px;
                    left: 37px;
                };
                i {
                    font-size: 25px;
                    color: #cfcfcf;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                span {
                    display: block;
                    color: $main-color;
                    text-transform: uppercase;
                    margin-bottom: 2px;
                    font: {
                        size: 13.5px;
                        weight: 600;
                    };
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    font-weight: 700;

                    &:hover, &:focus {
                        color: $main-color;
                    }
                }
                &:last-child {
                    padding-right: 0;
                    margin-right: 0;
                    border-right: none;
                }
            }
        }
    }
    .listing-price {
        text-align: center;

        .price {
            display: block;
            margin-bottom: 15px;
            position: relative;
            top: 6px;
            font: {
                size: 24px;
                weight: 700;
            };
        }
        .default-btn {
            border-radius: 5px;
        }
        .listing-review {
            display: block;
            margin-bottom: 10px;

            .review-stars {
                display: inline-block;

                i {
                    color: #f2b827;
                    font-size: 17px;
                    display: inline-block;
                }
            }
            .reviews-total {
                position: relative;
                color: $optional-color;
                position: relative;
                top: -2px;
                font-weight: 500;
                margin-left: 3px;
            }
        }
    }
}
.listing-details-image {
    img {
        width: 100%;
    }
}
.listing-details-image-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            transition: $transition;
            opacity: 0;
            visibility: hidden;

            [class*=owl-] {
                color: #87a1c1;
                font-size: 18px;
                margin: 0;
                padding: 0;
                background: $white-color;
                width: 45px;
                height: 45px;
                line-height: 42px;
                position: absolute;
                left: 20px;
                transition: $transition;
                top: 50%;
                padding-right: 2px !important;
                transform: translateY(-50%);
                border: {
                    radius: 50%;
                    style: solid;
                    color: $white-color;
                    width: 1.5px;
                };
                &.owl-next {
                    left: auto;
                    right: 20px;
                    padding: {
                        right: 0 !important;
                        left: 2px !important;
                    };
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                    border-color: $main-color;
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.listing-details-desc {
    margin-top: 35px;

    h3 {
        margin-bottom: 15px;
        font: {
            size: 25px;
            weight: 700;
        };
        &:not(first-child) {
            margin-top: 30px;
        }
    }
    .amenities-list {
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            padding-left: 0;
            margin: {
                left: -10px;
                right: -10px;
                bottom: -12px;
            };
            li {
                flex: 0 0 50%;
                max-width: 50%;
                padding: {
                    left: 10px;
                    right: 10px;
                    bottom: 12px;
                };
                span {
                    display: block;
                    color: $black-color;
                    position: relative;
                    padding-left: 27px;
                    line-height: 1.7;
                    font: {
                        weight: 600;
                        size: 14.5px;
                    };
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: $main-color;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    p {
        strong {
            color: $black-color;
        }
    }
    .description-features-list {
        padding-left: 15px;
        margin-bottom: 15px;
        list-style-type: none;

        li {
            color: $optional-color;
            margin-bottom: 10px;
            position: relative;
            padding-left: 17px;
            line-height: 1.8;

            &::before {
                position: absolute;
                left: 0;
                content: '';
                top: 10px;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: $main-color;
            }
            strong {
                color: $black-color;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .listing-author {
        margin-top: 20px;
        border-radius: 5px;
        background: #f8f8f8;

        .author-profile-header {
            height: 115px;
            border-radius: 5px 5px 0 0;
            background: {
                color: $main-color;
                position: center center;
                size: cover;
                repeat: no-repeat;
            };
        }
        .author-profile {
            padding: 0 25px 25px;

            .author-profile-title {
                position: relative;
                z-index: 1;
                margin: {
                    bottom: 15px;
                    top: -45px;
                };
                img {
                    display: inline-block;
                    border: 3px solid $white-color;
                    width: 100px;
                    height: 100px;
                }
                .author-profile-title-details {
                    margin-top: 15px;

                    .author-profile-details {
                        h4 {
                            margin-bottom: 5px;
                            font: {
                                size: 20px;
                                weight: 700;
                            };
                        }
                        span {
                            color: $optional-color;
                        }
                    }
                }
                .author-profile-raque-profile {
                    a {
                        display: inline-block;
                        text-transform: capitalize;
                        font: {
                            weight: 600;
                            size: 14px;
                        };
                    }
                }
            }
            p {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    #map {
        height: auto;
        
        iframe {
            width: 100%;
            height: 400px;
            border: none;
        }
    }
}

.panel-class{
    min-width: 50vw !important;
    max-height: 70vh !important;
}


/*================================================
Register Area CSS
=================================================*/
.register-image {
    height: 100%;
    width: 100%;
    background: {
        image: url(assets/img/register-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
}
.register-content {
    height: 100vh;
    @media (max-width: 767px) {
        padding: 10px;
        margin: 50px 15px 30px 15px;
    }
    .register-form {
        text-align: center;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
        };
        .logo {
            margin-bottom: 25px;
            
            a {
                display: inline-block;
            }
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 40px;
                weight: 700;
            };
        }
        p {
            margin: {
                top: 10px;
                bottom: 0;
            };
            a {
                display: inline-block;
            }
        }
        form {
            margin-top: 35px;

            .form-control {
                background-color: $white-color;
                color: $black-color;
                border: none;
                transition: $transition;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
                height: 55px;
                font: {
                    size: $font-size;
                };
                &::placeholder {
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                transition: $transition;
                display: block;
                width: 100%;
                padding: 14px 25px 13px;
                text-transform: uppercase;
                position: relative;
                background-color: $main-color;
                color: $white-color;
                box-shadow: 0px 5px 28.5px 1.5px rgba(8, 141, 211, .15);
                border: {
                    width: 2px;
                    style: solid;
                    color: $main-color;
                    radius: 5px;
                };
                font: {
                    size: 14.5px;
                    weight: 700;
                };
                &:hover, &:focus {
                    background-color: $main-color2;
                    color: $white-color;
                    border-color: $main-color2;
                }
            }
        }
    }
}

form {
    margin-top: 35px;

    .form-control, .form-select {
        background-color: $white-color;
        color: $black-color;
        border: none;
        transition: $transition;
        box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
        height: 55px;
        font: {
            size: $font-size;
        };
        &::placeholder {
            transition: $transition;
        }
        &:focus {
            &::placeholder {
                color: transparent;
            }
        }
    }
    button {
        transition: $transition;
        display: block;
        width: 100%;
        padding: 14px 25px 13px;
        text-transform: uppercase;
        position: relative;
        background-color: $main-color;
        color: $white-color;
        box-shadow: 0px 5px 28.5px 1.5px rgba(8, 141, 211, .15);
        border: {
            width: 2px;
            style: solid;
            color: $main-color;
            radius: 5px;
        };
        font: {
            size: 14.5px;
            weight: 700;
        };
        &:hover, &:focus {
            background-color: $main-color2;
            color: $white-color;
            border-color: $main-color2;
        }
    }
}

.listing-author {
    margin-top: 20px;
    border-radius: 5px;
    background: #f8f8f8;

    .author-profile-header {
        height: 115px;
        border-radius: 5px 5px 0 0;
        background: {
            color: $main-color;
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
    .author-profile {
        padding: 0 25px 25px;

        .author-profile-title {
            position: relative;
            z-index: 1;
            margin: {
                bottom: 15px;
                top: -45px;
            };
            img {
                display: inline-block;
                border: 3px solid $white-color;
                width: 100px;
                height: 100px;
            }
            .author-profile-title-details {
                margin-top: 15px;

                .author-profile-details {
                    h4 {
                        margin-bottom: 5px;
                        font: {
                            size: 20px;
                            weight: 700;
                        };
                    }
                    span {
                        color: $optional-color;
                    }
                }
            }
            .author-profile-raque-profile {
                a {
                    display: inline-block;
                    text-transform: capitalize;
                    font: {
                        weight: 600;
                        size: 14px;
                    };
                }
            }
        }
        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;
    background: {
        image: url(assets/img/coming-soon-bg.jpg);
        position: top center;
        size: cover;
        repeat: no-repeat;
    };
}
.coming-soon-content {
    max-width: 700px;
    background: $white-color;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    padding: 40px 60px;
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
    margin: {
        left: auto;
        right: auto;
    };
    .logo {
        display: inline-block;
    }
    h2 {
        font: {
            size: 40px;
            weight: 700;
        };
        margin: {
            top: 30px;
            bottom: 0;
        };
    }
    #timer {
        margin-top: 40px;

        div {
            background-color: #333333;
            color: $white-color;
            width: 100px;
            height: 105px;
            border-radius: 5px;
            font: {
                size: 40px;
                weight: 700;
                family: $font-family2;
            };
            margin: {
                left: 10px;
                right: 10px;
            };
            span {
                display: block;
                margin-top: -2px;
                font: {
                    size: 16px;
                    weight: 500;
                };
            }
        }
    }
    form {
        position: relative;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
            top: 50px;
        };
        .form-group {
            margin-bottom: 25px;
            width: 100%;
            position: relative;

            .label-title {
                margin-bottom: 0;
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                pointer-events: none;
                width: 100%;
                height: 100%;
                color: $black-color;

                i {
                    position: absolute;
                    left: 0;
                    transition: $transition;
                    top: 9px;
                    font: {
                        size: 22px;
                    };
                }
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    transition: $transition;
                    background: $main-color;
                }
            }
            .input-newsletter {
                border-radius: 0;
                border: none;
                border-bottom: 2px solid #eeeeee;
                padding: 0 0 0 32px;
                color: $black-color;
                height: 45px;
                display: block;
                width: 100%;
                transition: $transition;
                font: {
                    size: 16px;
                    weight: 400;
                };
                &::placeholder {
                    color: #A1A1A1;
                    transition: $transition;
                }
                &:focus {                    
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
        }
        .default-btn {
            border-radius: 0;
        }
        .validation-danger {
            margin-top: 15px;
            color: red;
        }
        .validation-success {
            margin-top: 15px;
        }
        p {
            margin: {
                bottom: 0;
                top: 20px;
            };
        }
    }
}

.full-screen-modal {
    max-width: unset !important;
    width: 65%;
    height: 75%;
    .mat-dialog-container {
      max-width: 65vw;
      max-height: 75vh;
      height: 65%;
      width: 75%;
      .mat-dialog-content {
        max-height: unset !important;
      }
    }
  }
.full-width-modal {
    max-width: unset !important;
    width: 65%;
    .mat-dialog-container {
      max-width: 65vw;
      width: 65%;
      .mat-dialog-content {
        max-height: unset !important;
      }
    }
  }
  
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
/* Quill */
@import '~quill/dist/quill.snow.css';
